import { getStores } from '@/api/ApiClient.js'

var mouseOverInfoWindow = false;

export async function getLocation() {
    return new Promise((resolve, reject) => {

        if (!("geolocation" in navigator)) {
            reject(new Error('Geolocation is not available.'));
        }

        navigator.geolocation.getCurrentPosition(pos => {
            resolve(pos);
        }, err => {
            reject(err);
        }, { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true });
    });
}

export async function getZipByLatLng(latitude, longitude) {

    return new Promise((resolve, reject) => {
        try {
            var point = new window.google.maps.LatLng(latitude, longitude);
            new window.google.maps.Geocoder().geocode({ 'latLng': point },
                function(results) {
                    results.forEach(function(item) {
                        if (item.types[0] == 'postal_code') {
                            resolve(item.address_components[0].short_name)
                        }
                    });
                    reject("")
                }
            );
        } catch(err) {
            console.error('Error in Google API')
        }  
    });
}

export async function getStoresLocations(zip) {
    var params = {}
    if (zip) {
        params = { search: zip }
    }
    var stores = await getStores(params)
    var locations = []
    stores.forEach(store => {
        locations.push(addStoreLocation(store))
    });
    return locations;
}

function addStoreLocation(store) {
    var location = {}
    if (store.coordinates) {
        var tipo = 2;
        if (store.is_repair_center) { tipo = 2; } else { tipo = 1 }
        var parts = store.coordinates.split(',');
        location = {
            type: tipo,
            data: {
                name: store.name,
                address: store.address,
                phone: store.phone,
                phone_number: store.phone_number,
                city: store.city,
                zip: store.zip,
                province: store.province
            },
            content: '<b>' + store.name + '</b>' +
                '<p>' + store.address + '<br><a href="tel:' + store.phone + '">' + store.phone_number + '</a></p>',
            location: {
                lat: parseFloat(parts[0]),
                lng: parseFloat(parts[1]),
            }
        };
    }
    return location;
}

export function addMarkers(storeLocations, googleMap) {
    var markers = [];
    var latlngbounds = new window.google.maps.LatLngBounds();
    var mapPin = require('@/assets/stores/pin-logo-movil.png');
    var mapPinGray = require('@/assets/stores/pin-logo-gris-movil.png');
    var foundValidStores = false;

    storeLocations.forEach((store) => {
        if (store != undefined && store != "undefined" && store.location != undefined && store.location.lat != undefined && store.location.lng != undefined) {
            foundValidStores = true
            var icon = ""
            if (store.type == 1) {
                icon = mapPin
            } else {
                icon = mapPinGray
            }
            var marker = new window.google.maps.Marker({
                animation: window.google.maps.Animation.DROP,
                position: {
                    lat: store.location.lat,
                    lng: store.location.lng
                },
                map: googleMap,
                icon: icon
            });
            markers.push(marker);
            latlngbounds.extend(new window.google.maps.LatLng(store.location.lat, store.location.lng));

            var contentString = store.content;
            var infowindow = new window.google.maps.InfoWindow({
                content: contentString
            });

            var touchDevice = ('ontouchstart' in document.documentElement);
            if (touchDevice) {
                marker.addListener('click', function() {
                    infowindow.open(googleMap, marker);
                });
            } else {
                var timeoutID;
                marker.addListener('mouseover', function() {
                    if (timeoutID) clearTimeout(timeoutID);
                    openInfoWindow(googleMap, marker, infowindow);
                });
                marker.addListener('mouseout', function() {
                    timeoutID = setTimeout(function() {
                        if (!mouseOverInfoWindow) {
                            closeInfoWindow(infowindow);
                        }
                    }, 400);
                });
            }
        
            marker.setMap(googleMap);
        }
    });

    if (foundValidStores) {
        googleMap.setZoom(10);
        googleMap.fitBounds(latlngbounds);
    }

    window.google.maps.event.addListenerOnce(googleMap, "zoom_changed", function() { googleMap.getZoom(); });
    window.google.maps.event.addListenerOnce(googleMap, "center_changed", function() { googleMap.getCenter(); });

    var mcOptions = {
        maxZoom: 16,
        zoomOnClick: true,
        minimumClusterSize: 2,
        imagePath: '/assets/markers/m'
    };
    new window.MarkerClusterer(googleMap, markers, mcOptions);
}

function openInfoWindow(googleMap, marker, infoWindow) {
    infoWindow.open(googleMap, marker);
    window.google.maps.event.addListener(infoWindow, 'domready', function() {
        addOpenInfoWindowListeners(infoWindow);
    });
}

function closeInfoWindow(infoWindow) {
    infoWindow.close();
}

function addOpenInfoWindowListeners(infowindow) {
    var infoWindowElement = document.querySelector('.gm-style .gm-style-iw')
    if (infoWindowElement != null) {
        var info = infoWindowElement.parentNode
        info.addEventListener('mouseleave', function() {
            closeInfoWindow(infowindow);
            mouseOverInfoWindow = false;
        });

        info.addEventListener('mouseenter', function() {
            mouseOverInfoWindow = true;
        });
    }
}