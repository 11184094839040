<template>
    <div>
        <HeaderOptions :currentLanding="currentLanding"/>
        <DiscountsBoxComponent :landing="landing"/>
        <FooterComponent v-if="currentLanding !== 'insurama'"/>
        <HomeFooterComponent v-if="currentLanding === 'insurama'"/>
    </div>
</template>

<script>
import HeaderOptions from '@/components/header/HeaderOptions'
import DiscountsBoxComponent from '@/components/discounts/DiscountsBoxComponent';
import HomeFooterComponent from "@/components/footer/HomeFooterComponent";
import FooterComponent from "@/components/footer/FooterComponent";

import { getters } from '@/state/store'

export default {
    name: 'DiscountsBox',
    components: {
        HeaderOptions,
        DiscountsBoxComponent,
        FooterComponent,
        HomeFooterComponent
    },
    props: {
        landing: {
            type: String,
            required: true
        }
    },
    computed: {
        currentLanding: getters.currentLanding
    }
}
</script>
