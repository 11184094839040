<template>
    <div class="container">
        <div class="price_content">
            <div class="price-box">
                <div v-if="currentLanding != landing.seguromultidispositivo.name" class="row">
                    <div class="col-9 content-price">
                        <span class="section-title">
                        <img v-show="policyData.isLoadingPolicyPrice" src='@/assets/loader.gif' height=20 style='margin-right:10px;'>
                        <div v-show="!policyData.isLoadingPolicyPrice">$ {{policyData.policyPrice}}/<span id="label_periodicity">{{periodicityLabel}}</span></div>
                        </span>
                    </div>
                    <div class="col-3 content-description"><strong>{{policyData.product.name}}</strong></div>
                </div>
                <div class='row' v-if="currentLanding == landing.seguromultidispositivo.name">
                    <div class='seccion-description'>
                        <div class='col-12 titulo-seccion'>
                            <span class='insurance-title bold mt-2'>{{multiDeviceTitle}}</span>
                        </div>
                        <div class='titulo-seccion-precio'>
                            <img v-show="policyData.isLoadingPolicyPrice" src='@/assets/loader.gif' height=20 style='margin-right:10px;'>
                            <div v-show="!policyData.isLoadingPolicyPrice">$ {{policyData.policyPrice}} /<span id="label_periodicity">{{periodicityLabel}}</span></div>
                        </div>
                    </div>
                    <div class='seccion-proteccion'>
                        <div class='icon-multi col-12'>
                            <img :src="capitalLogo">
                        </div>
                        <div class='protection-multi col-12'>
                            <span>{{$t('order.orderPrice.level_protection')}}</span>
                        </div>
                    </div>
                </div>
                <div v-if="currentLanding == landing.seguromultidispositivo.name && (policyData.risks[0].device || policyData.risks[0].purchase_price)" class="row mt-2">
                    <div class="col-2">
                        <img height="30" style="margin-top:0px;" :src="logo" align="left">
                    </div>
                    <div class="col-7">
                        <b>{{policyData.risks[0].device.name}}</b>
                    </div>
                    <div class="col-3 red text-right"><b>$ {{policyData.risks[0].purchase_price}}</b></div>
                </div>
                <div v-if="currentLanding == landing.seguromultidispositivo.name && (policyData.risks[1] !== undefined && policyData.risks[1].device || policyData.risks[1] !== undefined && policyData.risks[1].purchase_price)" class="row mt-2">
                    <div class="col-2">
                        <img height="30" style="margin-top:0px;" :src="logo" align="left">
                    </div>
                    <div class="col-7">
                        <b>{{policyData.risks[1].device.name}}</b>
                    </div>
                    <div class="col-3 red text-right"><b>$ {{policyData.risks[1].purchase_price}}</b></div>
                </div>
                <div v-if="currentLanding != landing.seguromultidispositivo.name && (policyData.device || policyData.purchasePrice)" class="row mt-2">
                    <div class="col-2">
                        <img height="30" style="margin-top:0px;" :src="logo" align="left">
                    </div>
                    <div class="col-7">
                        <b v-if="policyData.device">{{policyData.device.name}}</b>
                        <b v-else>{{policyData.model}}</b>
                    </div>
                    <div class="col-3 red text-right"><b>$ {{policyData.purchasePrice}}</b></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import landing from '@/config/constants/landing.js'

export default {
    name: 'OrderPrice',
    props: {
        currentLanding: {
            type: String
        },
        policyData: {
            type: Object
        },
        pageNumber: {
            type: Number
        }
    },
    data(){
        return {
            landing
        }
    },
    computed: {
        periodicityLabel() {
            return this.$t(this.policyData.periodicity == 1 ? 'order.periodicity_1' : 'order.periodicity_12');
        },
        multiDeviceTitle() {
            if(this.pageNumber == 1){
                return this.$t('order.orderPrice.all_risk')
            }else{
                return this.$t('order.orderPrice.capital_amount', { amount: this.policyData.capital.amount })
            }
        },
        logo() {
            if (!this.isMxBroker()) {
                try {
                    return require(`@/assets/logo/${this.$i18n.locale}_${this.currentLanding}.svg`)
                } catch (e) {
                    return require(`@/assets/logo/${this.$i18n.locale}_${this.currentLanding}.png`)
                }
            } else {
                return require(`@/assets/fav-icons/${this.$i18n.locale}_${this.currentLanding}.png`)
            }
        },
        capitalLogo() {
            if(this.policyData.capital.amount){
                return require(`@/assets/order/prot/prot-${this.policyData.capital.amount}.png`)
            }else{
                return require(`@/assets/order/prot/prot-300.png`)
            }
        }
    },
    methods: {
        isMxBroker() {
           return this.$i18n.locale == 'mx'
        }
    }
}

</script>

<style lang="scss" scoped>

.container{
    max-width: 600px;
    margin: 0 auto;
}

.price-box {
    width: 100%;
    background-color: $gray-color-light;
    padding: 8px;
    border-radius: 4px;
    -webkit-box-shadow: 6px 6px 10px -5px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 6px 6px 10px -5px rgba(0, 0, 0, 0.46);
    box-shadow: 6px 6px 10px -5px rgba(0, 0, 0, 0.46);
}
.price-box .seccion-description{
    width: 58%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding-left: 15px;
}
.price-box .seccion-proteccion{
    width: 41%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}
.price_content .content-description{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.8rem;
}
.price-box .seccion-description .titulo-seccion{
    font-size: 1rem;
}
.price-box .seccion-description .titulo-seccion-precio{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: $red-color;
    font-weight: bold;
    font-size: 1.5em;
}
#label_periodicity {
    font-size: 1rem;
    display: inline;
}

.price-box .insurance-title {
    font-size: 1.4em;
}

.icon-multi img {
    width: 50%;
    max-width: 85px;
    margin: auto;
    display: block;
}

.protection-multi {
    text-align: center;
}

@media (max-width: 450px){
   .price-box .seccion-description .titulo-seccion{
        font-size: 0.8rem;
    }
}

@media (max-width: 400px){
    .price-box .seccion-description{
        width: 100%;
        text-align: center;
        padding: 0 20px;
    }
    .price-box .seccion-proteccion{
        width: 100%;
    }
}

</style>