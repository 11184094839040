<template>
    <div class="form-group price-info mt-4 mb-4">
      <label for="product_id">{{$t('order.paymentPeriodicity.question_periodicity')}}</label><br>
      <div class="container">
          <div class="row">
          <template v-for="periodicity in periodicities" >
                <div v-if="periodicity.show" class="check" v-bind:key="periodicity.id">
                    <div class="custom-control custom-checkbox mt-1 col-12">
                        <input type="radio" :id="'months_' + periodicity.months" name="months" :value="periodicity.months" class="custom-control-input" v-model="policyData.periodicity" :checked="policyData.periodicity == periodicity.months">
                        <label style="text-transform:uppercase" class= "custom-control-label" :for="'months_' + periodicity.months">{{periodicity.name}}</label>
                    </div>
                </div>
            </template>
            </div>
          <div class="row">
              <p class="asterisk-box">{{$t('order.paymentPeriodicity.asterisk')}}</p>
          </div>
      </div>
    </div>
</template>

<script>
/* API-REST */
import { getPolicyPriceParams } from '@/api/ApiClientParamsSetter.js'
import { getPolicyPrice } from '@/api/ApiClient.js'

export default {
    name: "PaymentPeriodicity",
    props: {
        currentLanding: {
            type: String
        },
        policyData: {}
    },
    data() {
        return {
            periodicities: [{
                name: this.$t('order.paymentPeriodicity.month'),
                months: 1,
                show: false,
            },{
                name: this.$t('order.paymentPeriodicity.anual'),
                months: 12,
                show: true,
            }]
        }
    },
    created(){
        this.checkAnualPrice(); 
    },
    methods: {
        async checkAnualPrice() {
            const anualPolicy = JSON.parse(JSON.stringify(this.policyData)); // Deep copy
            anualPolicy.periodicity = 12;
            var params = getPolicyPriceParams(this.currentLanding, anualPolicy)
            var anualPrice = await getPolicyPrice(params);
            const showMonthlyPeriodicity = parseFloat(anualPrice) >= 1200;
            this.periodicities[0].show = showMonthlyPeriodicity;
            if(!showMonthlyPeriodicity) this.policyData.periodicity = 12;
        }
    }
}

</script>

<style lang="scss">

.price-info label{
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

.price-info .check{
    width: 35%;
}

.custom-control-label {
    margin-top: 5px;
}

.custom-control-input:checked~.custom-control-label::before {
    color: $white-color;
    border-color: $orange-color !important;
    background-color: $orange-color !important;
}

.asterisk-box {
    font-size: .6rem;
    margin-top: 20px;
}

@media (max-width: 400px){
    .price-info .check{
        width: 50%;
    }
}

</style>
