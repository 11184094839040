<template>
  <div :id="isEnableHomePage && currentLanding == 'insurama' ? 'mainPageHome' : 'mainPage'">
    <HeaderComponent v-if="currentLanding != 'insurama'"/>
    <HomeHeaderComponent v-if="isEnableHomePage && currentLanding == 'insurama'"/>
    <router-view/>
    <DoubtsComponent v-if="currentLanding != 'insurama'"/>
    <FooterComponent v-if="currentLanding != 'insurama'"/>
    <HomeFooterComponent v-if="isEnableHomePage && currentLanding == 'insurama'"/>
  </div>
</template>
<script>

import HomeHeaderComponent from "@/components/header/HomeHeaderComponent.vue";
import HomeFooterComponent from "@/components/footer/HomeFooterComponent.vue";

import HeaderComponent from "@/components/header/HeaderComponent.vue";
import DoubtsComponent from '@/components/doubts/DoubtsComponent.vue';
import FooterComponent from "@/components/footer/FooterComponent.vue";
import { bus } from '@/main.js';
import events from '@/config/constants/events.js'
import { getters } from '@/state/store.js'

export default {
  name: 'mainPage',
  components: {
    HeaderComponent,
    DoubtsComponent,
    FooterComponent,
    HomeHeaderComponent,
    HomeFooterComponent
  },
  computed: {
    currentLanding: getters.currentLanding
  },
  data(){
    return{
      url: process.env.VUE_APP_INSURAMA_API_URL,
      isEnableHomePage: process.env.VUE_APP_SHOW_HOME_PAGE === 'true'
    }
  },
  created () {
    if (!this.isEnableHomePage && this.currentLanding == 'insurama') {
          this.$router.push('/404');
    }
    
    bus.$on(events.faqsButtonClicked, () => {
      if(this.$route.name == 'home'){
        bus.$emit(events.scrollToFaqs)
      }else{
        this.$router.push({ name: 'home'}).catch(err => {console.log(err);})
        .then(function(){bus.$emit(events.scrollToFaqs)})
      }
    })
  }
}
</script>

<style lang="scss" scoped>
#mainPage {
  //font-family: Helvetica, Arial, sans-serif;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

