<template>
   <section class="calculate-price-section">
        <div class="container pt-3">
            <TopInfo :currentLanding= this.currentLanding />

            <SliderPrice
                v-if="isEnableOrderProcess"
                :currentLanding= this.currentLanding
                :type= sliderConfig.type
                :minValue= sliderConfig.minValue
                :maxValue= sliderConfig.maxValue
                :arrayValues= sliderConfig.arrayValues
                :stepValue= sliderConfig.stepValue
                :initialValue = sliderConfig.initialValue
                :moreAboveText= sliderConfig.moreAboveText
                :lessBelowText= sliderConfig.lessBelowText
                v-on:sliderStop = "sliderValueUpdated"/>

            <InsuranceBoxes 
                :currentLanding= this.currentLanding
                :sliderValue=  this.sliderValue />
        </div>	
    </section>
</template>

<script>

import TopInfo from "@/components/calculate-price/TopInfo.vue";
import SliderPrice from "@/components/calculate-price/SliderPrice.vue";
import InsuranceBoxes from "@/components/calculate-price/InsuranceBoxes.vue";
import sliderParams from '@/config/constants/sliderConfig.js'

export default {
    name: "CalculatePriceComponent",
    props: ['currentLanding'],
    components: {
        TopInfo,
        SliderPrice,
        InsuranceBoxes
    },
    data(){
        return {
            sliderValue: 0,
            sliderConfig: this.setSliderConfig(),
            isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
            sliderParams
        }
    },
    methods: {
        setSliderConfig(){
            return sliderParams[this.currentLanding]
        },
        sliderValueUpdated(value) {
            this.sliderValue = value
        }
    },
    mounted(){
        this.setSliderConfig()
    }
}

</script>

<style lang="scss" scoped>
@media(min-width: 768px) {
    .calculate-price-section {
        // background: url(~@/assets/calculate-price/fondo-slider.png) center top;
        // background-size: cover;
        // min-height: 300px;
        // background-position-y: -700px;
    }
}
</style>
