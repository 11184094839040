<template>
   <section>
        <div class="container">
            <div class="row">
              <div class="col">
                    <div class="text-center mt-5 section-title">{{ $t('advantagesComponent.title') }}</div>
              </div>
            </div>
            <div class="row mt-5 advantageRow">
                <div class="col-sm advantageContent">                                                                                                     
                    <div v-for="advantage in advantages" v-bind:key="advantage.title" class="text-center advantagesCard" >
                        <div class="row">
                            <div class="col-3">
                                <img class="text-center center-block" height=50 :src="getImgPath(advantage.imageName)" >
                            </div>
                            <div class="col-8">
                                <h5 class="red bold text-md-left text-sm-center">{{advantage.title}}</h5>
                                <p class="mb-4 text-md-left text-sm-center" v-html="advantage.description"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AdvantagesComponent",
    props: ['currentLanding'],
    data(){
        return{
            advantages: this.getAdvantages()
        }
    },
    methods: {
        getAdvantages() {
            var advantagesFiltered = this.$t('advantagesComponent.advantages').filter((x) => {return x.landing === this.currentLanding})[0]
            return advantagesFiltered.advantages 
        },
        getImgPath(imageName) {
            return require('../../assets/icons/'+ imageName)
        }
    }
}

</script>

<style lang="scss" scoped>

.advantageRow {
    background: #f9f9f9;
    box-shadow: 0px 6px 7px rgba(73, 83, 113, 0.46);
    position: relative;
    padding: 10px;
}

.advantageContent{
    display: flex;
    flex-wrap: wrap;
}

.advantagesCard {
    width: 48%;
    border-radius: 4px;
    background: $white-color;
    border: 0px solid #c4c4c4;
    margin: 8px;
    padding: 20px;
    min-height: 175px;
}

@media (max-width: 992px){
    .advantageContent {
        padding: 20px 0;
    }
    .advantagesCard {
        width: 100%;
    }
}
</style>
