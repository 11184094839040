<template>
  <section class="payment-container">
    <div class="container"> 
        <UserInputs
          :currentLanding = this.currentLanding
          :policyData="policyData"
          :userData="userData"
          :step2BtnClicked="step2BtnClicked" />     
    </div>
  </section>
</template>

<script>

/* Components */
import UserInputs from './elements/UserInputs.vue';

/* Constants */
import landing from '@/config/constants/landing.js'

export default { 
  props: {
    currentLanding: {
      type: String
    },
    policyData: {},
    userData: {},
    step2BtnClicked: {
      type: Boolean
    }
  },
  components: {
    UserInputs
  },
  data() {
    return {
      landing
    }
  }
}

</script>

<style lang="scss">

.payment-container .container{
  max-width: 600px;
}

</style>