<template>
  <section>
      <div id="home" v-if="isEnableHomePage && currentLanding == 'insurama'">
          <section id="quienes-somos" class="quienes-somos container">
              <div class="row">
                  <div class="col-lg-6 bg-img">
                      <div class="img d-flex align-items-center justify-content-center">
                          <img src="@/assets/home/index/quienes-somos-img.png" alt="quienes somos img">
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <h2 v-html="$t('homeContentComponent.title')"></h2>
                      <p v-html="$t('homeContentComponent.paragraph1')"></p>
                      <p v-html="$t('homeContentComponent.paragraph2')"></p>
                      <p v-html="$t('homeContentComponent.paragraph3')"></p>
                  </div>
              </div>
          </section>
          <section id="equipo" class="equipo container">
              <h2 v-html="$t('homeContentComponent.team_title')"></h2>
              <div class="row">
                  <div class="col-lg-4 col-sm-12 member special-margin-down">
                      <img src="@/assets/home/index/Jose-Manuel-Criado.jpg" alt="Juan Manuel Criado">
                      <h5>{{ $t('homeContentComponent.coo') }}</h5>
                      <h3>Juan Manuel Criado</h3>
                      <p>{{ $t('homeContentComponent.coo_description') }}</p>
                  </div>
                  <div class="col-lg-4 col-sm-12 member">
                      <img src="@/assets/home/index/Sergio-Balsa.jpg" alt="Sergio Balsa Pardo">
                      <h5>{{ $t('homeContentComponent.ceo') }}</h5>
                      <h3>Sergio Balsa Pardo</h3>
                      <p>{{ $t('homeContentComponent.ceo_description') }}</p>
                  </div>
                  <div class="col-lg-4 col-sm-12 member special-margin-down">
                      <img src="@/assets/home/index/Maria-Reboredo.jpg" alt="Maria Reboredo">
                      <h5>{{ $t('homeContentComponent.claims_director') }}</h5>
                      <h3>Maria Reboredo</h3>
                      <p>{{ $t('homeContentComponent.claims_director_description') }}</p>
                  </div>
                  <div class="col-lg-4 col-sm-12 member">
                      <img src="@/assets/home/index/Karlos-Presumido.jpg" alt="Karlos Presumido">
                      <h5>{{ $t('homeContentComponent.cto') }}</h5>
                      <h3>Karlos Presumido</h3>
                      <p>{{ $t('homeContentComponent.cto_description') }}</p>
                  </div>
                  <div class="col-lg-4 col-sm-12 member special-margin-up">
                      <img src="@/assets/home/index/Agustin-Diaz-Portales.jpg" alt="Agustín Díaz-Portales">
                      <h5>{{ $t('homeContentComponent.cmo') }}</h5>
                      <h3>Agustín Díaz-Portales</h3>
                      <p>{{ $t('homeContentComponent.cmo_description') }}</p>
                  </div>
                  <div class="col-lg-4 col-sm-12 member">
                      <img src="@/assets/home/index/Jose-Lopez-Taboada.jpg" alt="José López Taboada">
                      <h5>{{ $t('homeContentComponent.cfo') }}</h5>
                      <h3>José López Taboada</h3>
                      <p>{{ $t('homeContentComponent.cfo_description') }}</p>
                  </div>
              </div>
          </section>
      </div>

      <div id="home" v-if="currentLanding != 'insurama'">
        <OfferModal ref="offerModalRef" />
        <TakeNowSliderComponent :currentLanding= this.currentLanding v-on:goToOrderStep="pushGTMDataAndRedirect"/>
        <CalculatePriceComponent :currentLanding= this.currentLanding ref="calculatePriceComponent"/>
        <!--<ReviewsComponent/>-->
        <HowWorksComponent :currentLanding= this.currentLanding />
        <StoresComponent  :showStoreList= false />
        <AdvantagesComponent :currentLanding= this.currentLanding />
        <SliderOffersComponent :currentLanding= this.currentLanding />
        <FrequentQuestionsComponent ref="faqs" :currentLanding= this.currentLanding />
      </div>
  </section>
</template>

<script>
import TakeNowSliderComponent from "@/components/take-now-slider/TakeNowSliderComponent";
import CalculatePriceComponent from "@/components/calculate-price/CalculatePriceComponent";
import HowWorksComponent from "@/components/how-works/HowWorksComponent";
import StoresComponent from "@/components/stores/StoresComponent";
import AdvantagesComponent from "@/components/advantages/AdvantagesComponent";
import SliderOffersComponent from "@/components/slider-offers/SliderOffersComponent";
import FrequentQuestionsComponent from "@/components/frequent-questions/FrequentQuestionsComponent";
import { getters } from '@/state/store.js'
import { bus } from '@/main.js';
import events from '@/config/constants/events.js'
import landing from '@/config/constants/landing.js'
import OfferModal from '@/components/modals/OfferModal'

export default {
  name: 'Home',
  components: {
    TakeNowSliderComponent,
    CalculatePriceComponent,
    HowWorksComponent,
    StoresComponent,
    AdvantagesComponent,
    SliderOffersComponent,
    FrequentQuestionsComponent,
    OfferModal
  },
  data(){
    return {
        isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
        isEnableHomePage: process.env.VUE_APP_SHOW_HOME_PAGE === 'true'
    }
  },
  methods: {
    scrollToFrequentQuestionsComponent() {
        var el = this.$refs["faqs"].$el
        window.scrollTo({top: el.getBoundingClientRect().top + window.pageYOffset - 100, behavior: 'smooth'})
    },
    pushGTMDataAndRedirect(link) {
        /* TODO: Temporary Solution */
        let evetName = "";
          switch (this.currentLanding) {
            case landing.seguromovil.name:
                evetName='coste_movil';
                break;
            case landing.seguromultidispositivo.name:
                evetName='coste_multidispositivo';
                break;
            case landing.seguroportatil.name:
                evetName='coste_portatil';
                break;
            default:
                evetName='';
        }

        this.pushGTM('funnel', evetName, 'paso0', '')
        this.$router.push(link);
    },
    showOfferModal(){
      this.$refs.offerModalRef.showModal()
    }
  },
  computed: {
    currentLanding: getters.currentLanding
    // or: ...getters
  },
  created (){
    bus.$on(events.scrollToFaqs, () => {
      this.scrollToFrequentQuestionsComponent()
    })
  },
  mounted(){
    //if(this.currentLanding == landing.seguromovil.name && this.isEnableOrderProcess){
    //  this.showOfferModal()
    //}
  }
}

</script>

<style lang="scss" scoped>

</style>

