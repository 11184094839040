<template>
    <div class="data-resume-box">
      <div class="resume-box p-4">
      <div class="row">
        <div class="brand col-12 col-lg-6">
            <div v-if="currentLanding == landing.seguromovil.name" class="form-group">
                <label for="device">{{deviceLabelTitle}}</label>
                <autocomplete :search="searchDevice" :placeholder="$t('order.devicePriceSelector.select_device')" :aria-label="$t('order.devicePriceSelector.select_device')" 
                    :get-result-value="getResultValue" @submit="handleSubmit" @blur="handleSubmit"  base-class="autocomplete" :class="isValidSelectedDeviceClass(this.devices, policyData.device, this.incorrectDevice, step1BtnClicked)" ref="autocompleteElem"></autocomplete>
            </div>
            <div v-else-if="currentLanding != landing.seguromultidispositivo.name" class="form-group">
                <label for="model">{{deviceLabelTitle}}</label>
                <div class="input-group">
                    <input type="text" class="form-control flexdatalist" 
                        :class="isValidTextClass(policyData.model, 4, step1BtnClicked)"
                        v-on:blur="isValidTextField(policyData.model, 4, step1BtnClicked) ? pushGTM('funnel', 'marca_portatil', 'paso1', policyData.model, policyData.product.name) : ''"
                        placeholder="Introduce el modelo o marca" v-model="policyData.model"
                    >
                    <div class="input-group-append">
                        <div class="input-group-text" :class="isValidTextClass(policyData.model, 4, step1BtnClicked)"><mdi-icon icon="mdiKeyboard" /></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Laptop and mobile -->
        <div v-if="currentLanding != landing.seguromultidispositivo.name" class="col-12 col-lg-6">
            <div class="form-group">
                <label for="purchase_price">
                  {{$t('order.devicePriceSelector.price')}}
                  <div style="display:inline" class="d-none d-sm-inline ">{{priceLabelTitle}}</div>
                </label>
                <div class="input-group">
                  <input type="number" class="form-control" :class="isValidPriceClass(policyData.purchasePrice, step1BtnClicked)" placeholder="p.e. 399" v-on:blur="emitPriceToParent" @keyup.enter="emitPriceToParent" v-model="price">                                            
                  <div class="input-group-append">
                      <div class="input-group-text" :class="isValidPriceClass(policyData.purchasePrice, step1BtnClicked)"><mdi-icon icon="mdiCurrencyUsd" /></div>
                  </div>
                </div>
            </div>
        </div>
        </div>
        <!-- Multidispositivo -->
        <div v-if="currentLanding == landing.seguromultidispositivo.name" class="brand-multidispositivo">
             <div class="modelo_01 row">
                <div class="form-group col-12 col-lg-6">
                    <label for="device">{{deviceLabelTitle}} 1</label>
                    <autocomplete :search="searchDevice" :placeholder="$t('order.devicePriceSelector.select_device')" :aria-label="$t('order.devicePriceSelector.select_device')" 
                        :get-result-value="getResultValue" @submit="handleSubmit" @blur="handleSubmit"  base-class="autocomplete" :class="isValidSelectedDeviceClass(this.devices, policyData.risks[0].device, this.incorrectDeviceRisks[0], step1BtnClicked)" ref="autocompleteElem1"></autocomplete>
                </div>
                <div class="form-group col-12 col-lg-6">
                    <label for="purchase_price">{{$t('order.devicePriceSelector.price')}}
                        <div style="display:inline" class="d-none d-sm-inline ">{{$t('order.devicePriceSelector.price_label_movil_1')}}</div>
                    </label>
                    <div class="input-group">
                        <input type="number" class="form-control" 
                            :class="isValidPriceClass(policyData.risks[0].purchase_price, step1BtnClicked)" 
                            placeholder="p.e. 399" v-model="policyData.risks[0].purchase_price"
                            v-on:blur="pushMultidispositivoPriceGTM(0)"
                        >                                            
                        <div class="input-group-append">
                            <div class="input-group-text" :class="isValidPriceClass(policyData.risks[0].purchase_price, step1BtnClicked)"><mdi-icon icon="mdiCurrencyUsd" /></div>
                        </div>
                    </div>
                </div>
             </div>
            <div v-if="!multiDeviceCollapsed" class="modelo_02 row">
                <div class="form-group col-12 col-lg-6">
                    <label for="device">{{deviceLabelTitle}} 2</label>
                    <autocomplete id="device" :search="searchDevice" :placeholder="$t('order.devicePriceSelector.select_device')" :aria-label="$t('order.devicePriceSelector.select_device')" 
                        :get-result-value="getResultValue" @submit="handleSubmit" @blur="handleSubmit"  base-class="autocomplete" :class="isValidSelectedDeviceClass(this.devices, policyData.risks[1].device, this.incorrectDeviceRisks[1], step1BtnClicked)" ref="autocompleteElem2"></autocomplete>
                </div>
                <div class="form-group col-12 col-lg-6">
                    <div class="form-group">
                        <label for="purchase_price">
                        {{$t('order.devicePriceSelector.price')}}
                        <div style="display:inline" class="d-none d-sm-inline ">{{$t('order.devicePriceSelector.price_label_movil_2')}}</div>
                        </label>
                        <div class="input-group">
                        <input type="number" class="form-control" 
                            :class="isValidPriceClass(policyData.risks[1].purchase_price, step1BtnClicked)" 
                            placeholder="p.e. 399" v-model="policyData.risks[1].purchase_price"
                            v-on:blur="pushMultidispositivoPriceGTM(1)"
                        >                                            
                        <div class="input-group-append">
                            <div class="input-group-text" :class="isValidPriceClass(policyData.risks[1].purchase_price, step1BtnClicked)"><mdi-icon icon="mdiCurrencyUsd" /></div>
                        </div>
                        </div>
                    </div>
             </div>
            </div>
        </div>
        <!-- Laptop -->
        <div v-if="currentLanding != landing.seguromovil.name && currentLanding != landing.seguromultidispositivo.name" class="row" >
            <div class="preference_01 col-12 col-lg-6">
                <div class="form-group">
                    <label for="preference">{{referenceLabelTitle}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" :class="isValidTextClass(policyData.reference, 1, step1BtnClicked)" placeholder="p.e. X123XX2" 
                            v-model="policyData.reference" 
                            v-on:blur="isValidTextField(policyData.reference, 1) ? pushGTM('funnel', 'referencia', 'paso1', '', policyData.product.name) : ''">
                        <div class="input-group-append">
                            <div class="input-group-text" :class="isValidTextClass(policyData.reference, 1, step1BtnClicked)"><mdi-icon icon="mdiBarcode" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preference_02 col-12 col-lg-6">
                <div class="form-group" >
                    <label for="purchase_date" >{{$t('order.devicePriceSelector.date_of_purchase')}}</label>
                    <div class="input-group">
                        <b-form-datepicker :class="isValidPurchaseDateClass(this.currentLanding, this.policyData.purchaseDate, step1BtnClicked)" id="example-datepicker" v-model="policyData.purchaseDate" :max="max" show-decade-nav hide-header placeholder="dd/mm/aaaa" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" locale="es"></b-form-datepicker>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div v-if="currentLanding == landing.seguromultidispositivo.name && policyData.capital.amount > 7000" class="col-12 mt-4" @click="setCollapsable()">
            <a v-if="multiDeviceCollapsed" href='javascript:void(0)' style='text-decoration:none;' class='orange'><mdi-icon icon="mdiPlus" /> dispositivos </a>
            <a v-else href='javascript:void(0)' style='text-decoration:none;' class='orange'><mdi-icon icon="mdiMinus" /> dispositivos </a>
      </div>
      <div v-if="currentLanding == landing.extensiondegarantia.name" class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="device_type">{{$t('order.devicePriceSelector.type')}}</label>
                    <div class="input-group">
                        <select class="form-control" v-model="policyData.device_type" :class="isValidTextClass(policyData.device_type, 1, step1BtnClicked)">
                            <option value="">-- {{$t('order.devicePriceSelector.select')}} --</option>
                            <option v-for="deviceType in devicesTypes" :value="deviceType" v-bind:key="deviceType.id" >{{deviceType}}</option>
                        </select>
                        <div class="input-group-append">
                            <div class="input-group-text "><mdi-icon icon="mdiLaptop" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getDeviceModels } from '@/api/ApiClient.js'
import landing from '@/config/constants/landing.js'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

export default {
    name: "DevicePriceSelector",
    props: {
        currentLanding: {
            type: String
        },
        policyData: {},
        step1BtnClicked: {
            type: Boolean
        }
    },
    watch:{
        'policyData.capital.amount'() {
            this.multiDeviceCollapsed = true
            if(this.policyData.risks[1] !== undefined){
                this.policyData.risks[1].device = ""
                this.policyData.risks[1].purchase_price = ""
            }
        }
    },
    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        return {
            landing,
            devices: "",
            devicesTypes: this.getDevicesTypes(),
            price: this.policyData.purchasePrice,
            max: new Date(today),
            multiDeviceCollapsed: true,
            incorrectDevice: false,
            incorrectDeviceRisks: [false,false]
        }
    },
    methods: {
        async getDevices(){
            this.devices = await getDeviceModels()
        },
        getDevicesTypes() {
            var devices_types = this.$t('order.devicePriceSelector.device_types')
            return devices_types
        },
        emitPriceToParent() {
            this.pushGTM('funnel', 'precio', 'paso1', this.price, this.policyData.product.name, true)
            this.$emit('devicePriceEntered', this.price)
        },
        setCollapsable() {
            if(!this.multiDeviceCollapsed){
                this.policyData.risks.pop();
            }else{
                this.policyData.risks.push({
                    "device": "",
                    "purchase_price": ""
                });
            }
            this.multiDeviceCollapsed = !this.multiDeviceCollapsed
        },
        searchDevice(textInput) {
            if (textInput.length < 1) { return [] }
                return this.devices.filter(device => {
                    if(device.name !== null){
                        return device.name.toLowerCase()
                        .includes(textInput.toLowerCase())
                    }
                })
        },
        getResultValue(result) {
            return result.name
        },
        handleSubmit(result) {
            if(this.devices.includes(result)){
                if(this.currentLanding == landing.seguromovil.name){
                    this.incorrectDevice = false;
                    this.policyData.device = result;
                    this.pushGTM('funnel', 'marca_extension', 'paso1', this.policyData.device.name, this.policyData.product.name, true);
                }else if(this.currentLanding == landing.seguromultidispositivo.name){
                    if(this.$refs.autocompleteElem1 !== undefined && this.$refs.autocompleteElem1.value !== '' && this.$refs.autocompleteElem1.value === result.name){
                        this.incorrectDeviceRisks[0] = false;
                        this.policyData.risks[0].device = result;
                        this.pushGTM('funnel', 'marca_movil1', 'paso1', this.policyData.risks[0].device.name, "", false);
                    }
                    if(this.$refs.autocompleteElem2 !== undefined && this.$refs.autocompleteElem2.value !== '' && this.$refs.autocompleteElem2.value === result.name){
                        this.incorrectDeviceRisks[1] = false;
                        this.policyData.risks[1].device = result;
                        this.pushGTM('funnel', 'marca_movil2', 'paso1', this.policyData.risks[1].device.name, "", false);
                    }
                }
            }else {
                if(this.currentLanding == landing.seguromovil.name){
                    if(this.$refs.autocompleteElem !== undefined && this.$refs.autocompleteElem.value !== ''&& !this.devices.some(device => device.name === this.$refs.autocompleteElem.value)){
                        this.incorrectDevice = true;
                        this.policyData.device = this.$refs.autocompleteElem.value;
                        this.policyData.device = '';
                    }else if(this.$refs.autocompleteElem !== undefined && this.$refs.autocompleteElem.value === ''){
                        this.incorrectDevice = false;
                        this.policyData.device = 'incorrect';
                        this.policyData.device = '';
                    }

                }else if(this.currentLanding == landing.seguromultidispositivo.name){
                    if(this.$refs.autocompleteElem1 !== undefined && this.$refs.autocompleteElem1.value !== '' && !this.devices.some(device => device.name === this.$refs.autocompleteElem1.value)){
                        //this.policyData.risks[0].device = this.$refs.autocompleteElem1.value;
                        this.incorrectDeviceRisks[0] = true;
                        this.policyData.risks[0].device = this.$refs.autocompleteElem1.value;
                        this.policyData.risks[0].device = '';
                    }else if(this.$refs.autocompleteElem1 !== undefined && this.$refs.autocompleteElem1.value === ''){
                        this.incorrectDeviceRisks[0] = false;
                        this.policyData.risks[0].device = 'incorrect';
                        this.policyData.risks[0].device = '';
                    }

                    if(this.$refs.autocompleteElem2 !== undefined && this.$refs.autocompleteElem2.value !== '' && !this.devices.some(device => device.name === this.$refs.autocompleteElem2.value)){
                        //this.policyData.risks[1].device = this.$refs.autocompleteElem2.value;
                        this.incorrectDeviceRisks[1] = true;
                        this.policyData.risks[1].device = this.$refs.autocompleteElem2.value;
                        this.policyData.risks[1].device = '';
                    }else if(this.$refs.autocompleteElem2 !== undefined && this.$refs.autocompleteElem2.value === ''){
                        this.incorrectDeviceRisks[1] = false;
                        this.policyData.risks[1].device = 'incorrect';
                        this.policyData.risks[1].device = '';
                    }

                }
            }
        },
        pushMultidispositivoPriceGTM(index) {
            if (this.isValidPrice(this.policyData.risks[index].purchase_price)) {
                const deviceNumber = index + 1;
                this.pushGTM('funnel', 'precio'+deviceNumber, 'paso1', this.policyData.risks[index].purchase_price, "", false)
            }
        }
    },
    created() {
        this.getDevices()
    },
    computed: {
        deviceLabelTitle() {
            switch (this.currentLanding) {
                case landing.seguromovil.name:
                    return this.$t('order.devicePriceSelector.device_label_movil')
                case landing.seguropatinete.name:
                    return this.$t('order.devicePriceSelector.device_label_scooter')
                case landing.seguromultidispositivo.name:
                    return this.$t('order.devicePriceSelector.device_label_movil')
                case landing.seguroportatil.name:
                    return this.$t('order.devicePriceSelector.device_label_laptop')
                case landing.extensiondegarantia.name:
                    return this.$t('order.devicePriceSelector.device_label_extension')
                default:
                    return this.$t('order.devicePriceSelector.device_label')
            }
        },
        priceLabelTitle() {
            switch (this.currentLanding) {
                case landing.seguromovil.name:
                    return this.$t('order.devicePriceSelector.price_label_movil')
                case landing.seguropatinete.name:
                    return this.$t('order.devicePriceSelector.price_label_scooter')
                case landing.seguromultidispositivo.name:
                    return this.$t('order.devicePriceSelector.price_label_movil_1')
                case landing.seguroportatil.name:
                    return this.$t('order.devicePriceSelector.price_label_laptop')
                case landing.extensiondegarantia.name:
                    return this.$t('order.devicePriceSelector.price_label_extension')
                default:
                    return this.$t('order.devicePriceSelector.device_label')
            }
        },
        referenceLabelTitle() {
            switch (this.currentLanding) {
                case landing.seguropatinete.name:
                    return this.$t('order.devicePriceSelector.frame')
                default:
                    return this.$t('order.devicePriceSelector.reference')
            }
        }
    },
    components: {
        Autocomplete
    }
}

</script>

<style lang="scss">

.data-resume-box .resume-box{
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    background-color: $gray-color-light;
    padding: 8px;
    margin: 0;
    border-radius: 4px;
    -webkit-box-shadow: 6px 6px 10px -5px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 6px 6px 10px -5px rgba(0, 0, 0, 0.46);
    box-shadow: 6px 6px 10px -5px rgba(0, 0, 0, 0.46);
}

.data-resume-box  .brand-multidispositivo{
    width: 100%;
}

#example-datepicker__outer_ label{
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 0;
}

button#example-datepicker {
    border-left: 1px solid #ced4da;
    background-color: #e9ecef;
    border-radius: 0;
    padding-right: 0.7em;
    color: #495057 !important;
}

.notValid button#example-datepicker {
    background: #ffc9c9!important;
    border-left-color: red!important;
}

.valid button#example-datepicker {
    background: #e8ffc9!important;
    border-left-color: #0b6b0b!important;
}

div#example-datepicker__dialog_ {
    transform: translate3d(-10px, 38px, 0px) !important;
}

.input-group .b-form-btn-label-control.form-control {
    padding: 0;
}

.b-form-btn-label-control.form-control {
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    height: auto;
    padding: 0;
    background-image: none;
}

.b-form-btn-label-control.form-control>.form-control {
    height: auto;
    min-height: calc(1.5em + 0.75rem);
    margin: 0;
    border: 0;
    outline: 0;
    background: transparent;
    word-break: break-word;
    font-size: inherit;
    white-space: normal;
    cursor: pointer;
}

.b-calendar .b-calendar-grid {
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.b-calendar .b-calendar-grid-body .col[data-date] .btn {
    width: 32px;
    height: 32px;
    font-size: 14px;
    line-height: 1;
    margin: 3px auto;
    padding: 9px 0;
}

div.brand > div.form-group > div.valid,
div.brand > div.form-group > div.notValid,
div.modelo_01 > div.form-group > div.valid,
div.modelo_01 > div.form-group > div.notValid,
div.modelo_02 > div.form-group > div.valid,
div.modelo_02 > div.form-group > div.notValid {
    border: none !important;
    background: none !important;
}

div.autocomplete > input {
    color: #495057;
    border: 1px solid #ced4da;
    background-color: #fff;
    height: calc(1.5em + .75rem + 2px);
}

div.valid > div.autocomplete > input {
    background-color: #e8ffc9 !important;
    border: 1px solid #0b6b0b !important;
}

div.notValid > div.autocomplete > input {
    background-color: #ffc9c9 !important;
    border: 1px solid #ff0000 !important;
}

div.autocomplete > ul {
    color: #495057;
    z-index: 2 !important;
}

@media (max-width: 490px){
   
    .data-resume-box .resume-box .form-group label {
        font-size: 0.8rem;
   }
    div#example-datepicker__dialog_ {
    transform: translate3d(0px, 38px, 0px) !important;
    }
}
</style>
