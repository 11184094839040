<template>
   <div :class="`${this.$i18n.locale}`">
        <nav id="navbarBot" class="navbar navbarBot navbar-expand-lg">
            <div class="container-fluid">
            <a class="navbar-brand d-flex" :href="navLink.insurama"><img id="logo" src="@/assets/home/index/Insurama-Logo-300px-1.png" alt="logo insurama"></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link active" @click="scrollMeTo('quienes-somos')" aria-current="page" href="#">{{ $t('homeHeaderComponent.about_us') }}</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link" :href="`${navLink.seguromovil}/mapa`">
                    {{ $t('homeHeaderComponent.repair_shops') }}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="scrollMeTo('equipo')" href="#">{{ $t('homeHeaderComponent.team') }}</a>
                </li>
                <li>
                        <!-- Button trigger modal -->
                    <button type="button" class="btn btn-primary" @click="showCallRequestModal" data-bs-toggle="modal" data-bs-target="#helpModal">
                        {{ $t('homeHeaderComponent.contact_us') }}
                    </button>
                </li>
                </ul>
            </div>
            </div>
        </nav>
        <nav id="navbarTop" class="navbar navbarTop navbar-expand-lg">
            <div class="container-fluid h-100">
                <ul class="sub_menu h-100 w-100 justify-content-around d-flex">
                    <li class="h-100">
                        <a :href="navLink.seguromovil" class="h-100">
                            <span class="menu-seguros__img h-100"><img :src="getMenuIcon('seguromovil')" :alt="$t('homeHeaderComponent.mobile_insurance')"></span>
                        </a>
                    </li>
                    <li class="h-100">
                        <a :href="navLink.seguromultidispositivo" class="h-100">
                            <span class="menu-seguros__img h-100"><img :src="getMenuIcon('seguromultidispositivo')" :alt="$t('homeHeaderComponent.multidevice_insurance')"></span>
                        </a>
                    </li>
                    <li class="h-100">
                        <a :href="navLink.seguroportatil" class="h-100">
                            <span class="menu-seguros__img h-100"><img :src="getMenuIcon('seguroportatil')" :alt="$t('homeHeaderComponent.laptop_insurance')"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <nav id="navbarTopMob" class="navbar navbarTop navMobile navbar-expand-lg">
            <div class="container-fluid h-100">
                <ul class="sub_menu h-100 w-100 justify-content-around d-flex">
                    <li class="h-100">
                        <a :href="navLink.seguromovil" class="h-100">
                            <span class="menu-seguros__img h-100"><img :src="getMenuIcon('seguromovil')" :alt="$t('homeHeaderComponent.mobile_insurance')"></span>
                        </a>
                    </li>
                    <li class="h-100">
                        <a :href="navLink.seguromultidispositivo" class="h-100">
                            <span class="menu-seguros__img h-100"><img :src="getMenuIcon('seguromultidispositivo')" :alt="$t('homeHeaderComponent.multidevice_insurance')"></span>
                        </a>
                    </li>
                    <li class="h-100">
                        <a :href="navLink.seguroportatil" class="h-100">
                            <span class="menu-seguros__img h-100"><img :src="getMenuIcon('seguroportatil')" :alt="$t('homeHeaderComponent.laptop_insurance')"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <header id="header">
            <div class="container">
                <div class="row d-flex flex-column">
                    <h1 v-html="$t('homeHeaderComponent.slide_message')"></h1>
                    <a href="#" @click="scrollMeTo('quienes-somos')" class="btn btn-primary">{{ $t('homeHeaderComponent.about_us') }} <i><mdi-icon icon="mdiArrowDown" /></i></a>
                </div>
            </div>
            <div class="nectar-shape-divider-wrap">
                <svg class="nectar-shape-divider" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">  
                    <path d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path> <path d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"></path> <path d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
                </svg>
            </div>
        </header>

        <CallRequestModal 
        ref="callRequestRef" 
        :phoneContact= "contactInfo.telephone"
        showQueryBox= false
        showHelpBox= true
        :showSinesterBox = false
        @show-thanks="showThanksModal" />

        <ThanksModal 
            ref="thanksRef" />
</div>
</template>

<script>
import CallRequestModal from '../../components/modals/CallRequestModal.vue'
import ThanksModal from '../../components/modals/ThanksModal.vue'
import contactInfo from '@/config/constants/contactInfo.js'
import navLink from '@/config/constants/navLink.js'

export default {
    name: "HomeHeaderComponent",
     components: { 
        CallRequestModal,
        ThanksModal
    },
      data(){
        return{
            contactInfo,
            navLink
        }
    },
    methods: {
        showCallRequestModal(){
            this.$refs.callRequestRef.showModal()
        },
        showThanksModal(){
            this.$refs.thanksRef.showModal()
        },
        toggleHam() {
            document.body.classList.toggle('nav-open')
        },
        scrollMeTo(id) {
            var element = document.getElementById(id);
            var top = element.offsetTop;
            window.scrollTo(0, top - 70);
        },
        isMxBroker() {
           return this.$i18n.locale == 'mx'
        },
        getMenuIcon(insurance) {
            const imageName = `${this.$i18n.locale}_${insurance}.svg`
            return require('../../assets/header/'+ imageName)
        },
    },
}
</script>

<style lang="scss">
#mainPageHome .navbar-toggler-icon {
    background-image: url('../../assets/home/index/nav-burguer.svg');
}

#mainPageHome header {
    background-image: url('../../assets/home/index/Home-Insurama-10.png');
}

#mainPageHome .mx .justify-content-around {
    justify-content: center !important;
    gap: 10px 35%;
}

#mainPageHome .mx .navbar ul li span {
    display: flex;
    align-items: center;
}

#mainPageHome .mx .menu-seguros__img img {
    height: 75%;
}

</style>