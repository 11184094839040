<template>
    <div id="container-order">
        <section v-if="step <= 2">
            <HeaderOrderComponent
            :currentLanding= this.currentLanding
            :pageNumber= this.step />
            <OrderTitle
                :currentLanding = this.currentLanding
                :pageNumber= this.step
                v-on:backPressed = "backStep" />
            <OrderPrice
                :currentLanding = this.currentLanding
                :pageNumber= this.step
                :policyData= policyData />
            <keep-alive>
                <component
                ref="currentComponent"
                    :is="currentComponent"
                    v-bind="currentProperties"
                    v-on:purchasePriceEntered = "setPurchasePrice"
                    v-on:discountCodeApplied = "setDiscountCode">
                </component>
            </keep-alive>
            <div class="container">
                <button type="button" class="btn-orange pulse-effect" @click="nextStep()">{{ buttonText }}</button>
            </div>
        </section>
        <div v-else class="text-center section-title">
            <p><b>{{$t('order.processing_data')}}</b></p>
            <img src='@/assets/loader.gif' width="70">
        </div>
        <ErrorModal
            ref="errorModalRef" />
    </div>
</template>

<script>
import HeaderOrderComponent from "@/components/order/common-components/HeaderOrderComponent.vue";
import OrderTitle from "@/components/order/common-components/OrderTitle.vue";
import OrderPrice from "@/components/order/common-components/OrderPrice.vue";
import OrderChooseInsurance from "@/components/order/step1/OrderChooseInsurance.vue";
import OrderBuyInsurance from "@/components/order/step2/OrderBuyInsurance.vue";
import ErrorModal from "@/components/modals/ErrorModal.vue"
import { getProductCategoryId } from '@/api/ApiClientUtils.js'
import { getters } from '@/state/store.js'
import landing from '@/config/constants/landing.js'

/* API-REST */
import { getPolicyPriceParams, getSubmitPolicyParams } from '@/api/ApiClientParamsSetter.js'
import { getPolicyPrice, submitPolicy } from '@/api/ApiClient.js'

export default {
    components: {
        HeaderOrderComponent,
        OrderTitle,
        OrderPrice,
        OrderChooseInsurance,
        OrderBuyInsurance,
        ErrorModal
    },
    data() {
        return {
            isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
            currentComponent: OrderChooseInsurance,
            step: 1,
            step1BtnClicked: false,
            step2BtnClicked: false,
            policyData: {
                purchasePrice: this.$route.query.price,
                idCategory: this.$route.query.id_category,
                product_id: this.$route.query.id,
                periodicity: {},
                policyPrice: "",
                isLoadingPolicyPrice: false,
                phone: "",
                product: {},
                reference: "",
                model: "",
                device_type: "",
                purchaseDate: "",
                device: "",
                capital: {},
                discountCode: "",
                risks: [
                {
                    "device": "",
                    "purchase_price": ""
                }]
            },
            userData: {
                nif: "",
                name: "",
                email: "",
                postal_code: "",
                birth_date: "",
                state: "",
                town: "",
                colony: "",
                street: "",
                exterior_number: "",
                inner_number: "",
                card_number: "",
                card_expiration_month: "",
                card_expiration_year: "",
                card_cvv: "",
                iban: "",
                acceptance_type: 0,
                acceptance_type_2: 0,
                acceptance_coverages: 0
            }
        }
    },
    watch:{
        'policyData.product'() { this.getPolicyPrice() },
        'policyData.periodicity'() { this.getPolicyPrice() },
        'policyData.capital'() { this.getPolicyPrice() },
        'policyData.purchaseDate'() {
            this.pushGTM('funnel', 'fecha_compra', 'paso1', this.policyData.purchaseDate, this.policyData.product.name)
        }
    },
    computed: {
        currentLanding: getters.currentLanding,
        currentProperties: function() {
            if (this.currentComponent === OrderChooseInsurance) {
                return {
                    currentLanding: this.currentLanding,
                    policyData: this.policyData,
                    step1BtnClicked: this.step1BtnClicked
                }
            }else{
                return {
                    currentLanding: this.currentLanding,
                    userData: this.userData,
                    policyData: this.policyData,
                    step2BtnClicked: this.step2BtnClicked
                }
            }
        },
        buttonText(){
            switch (this.step) {
                case 1:
                    return this.$t('order.next_button_step_1')
                case 2:
                    return this.$t('order.next_button_step_2')
                default:
                    return ""
            }
        },
    },
    methods: {
        setPurchasePrice(price){
            this.policyData.purchasePrice = price
            this.getPolicyPrice()
        },
        setDiscountCode(discountCodeApplied){
            this.policyData.discountCode = discountCodeApplied
            this.getPolicyPrice()
        },
        nextStep(){
            if(this.step == 1) {
                this.step1BtnClicked = true
                if(this.isValidOrderStep1(this.currentLanding, this.policyData)){
                    this.pushGTMUserID(this.policyData.phone)
                    this.pushGTM('funnel', 'confirmar_paso1', 'paso1', this.policyData.purchasePrice, this.policyData.product.name)
                    this.step = 2
                    this.currentComponent = OrderBuyInsurance;
                }
            }else{
                this.step2BtnClicked = true
                if(this.isValidOrderStep2(this.userData)){
                    this.pushGTM('funnel', 'confirmar_paso2', 'paso2', '', this.policyData.product.name)
                    this.createPolicy()
                }
            }
        },
        backStep(){
            this.pushGTM('funnel', 'atras', 'recurrente')
            this.step = 1
            this.currentComponent = OrderChooseInsurance;
        },
        async getPolicyPrice(){
            this.policyData.isLoadingPolicyPrice = true

            if (this.currentLanding != landing.seguromultidispositivo.name) {
                this.policyData.idCategory = getProductCategoryId(this.policyData.purchasePrice, this.policyData.product)
            } else {
                if (!this.policyData.capital.amount) this.policyData.capital.amount = 7000;

                const categoryID = getProductCategoryId(this.policyData.capital.amount, this.policyData.product);
                this.policyData.capital.categoryID = categoryID;
                this.policyData.idCategory = categoryID;
            }
            var params = getPolicyPriceParams(this.currentLanding, this.policyData)
            var policyPrice = await getPolicyPrice(params)
            if(parseFloat(policyPrice) > 0){
                this.policyData.policyPrice = policyPrice
            }else{
                this.policyData.policyPrice = this.$t('calculatePriceComponent.insuranceBoxes.price_not_available')
            }
            this.$refs.currentComponent.refreshPaymentPeriodicityComponent();
            this.policyData.isLoadingPolicyPrice = false
        },
        async createPolicy(){
            this.step = 3
            var params = getSubmitPolicyParams(this.currentLanding, this.policyData, this.userData)
            // UTM INFO
            var data = this.getUTMData();
            params.utm_source = data.utm_source;
            params.utm_medium = data.utm_medium;
            params.utm_campaign = data.utm_campaign;
            
            var response = await submitPolicy(params)
            if(response.isValidPolicy){
                
                this.step = 1
                // TODO: Activate cash payment
                //window.location.href = window.location.protocol + "//" + window.location.host + "/order-ok" + window.location.search;
                this.submitResponseForm(response);
            }else{
                this.step = 2
               
                this.$refs.errorModalRef.showModal(response.error)
            }
        },
        createElementFromHTML(htmlString) {
            var div = document.createElement('div');
            div.innerHTML = htmlString.trim();
            document.body.appendChild(div);
            return div.firstChild;
        },
        submitResponseForm(response) {
            this.createElementFromHTML(response.form).submit()
        }
    },
    created(){
        if(!this.isEnableOrderProcess) {
            this.$router.push({ name: 'home' })
        }

        this.policyData.periodicity = this.currentLanding == landing.extensiondegarantia.name ? 12 : 1;
        
        // Preselect the first product
        var insurancesFiltered = this.$t('calculatePriceComponent.insuranceBoxes.insurances').filter((x) => {return x.landing === this.currentLanding})[0]
        this.selectedInsurance = insurancesFiltered
        this.policyData.product = this.selectedInsurance.insurances.filter((insurance) => {return insurance.product_id == this.policyData.product_id})[0]
    }
}
</script>

<style lang="scss" scoped>

.container {
    max-width: 600px;

}

.pulse-effect {
    width: 100%;
    transition: all 0.300s;
    animation: pulse 0.6s infinite linear alternate;
    margin-bottom: 40px;
}

.pulse-effect:hover {
   background: $red-hover;
}

@keyframes pulse{
    0%{
        transform: scale(1);
    }100%{
        transform: scale(1.05);
    }
}
.wrapper-menu .wrapper-menu-contract {
    flex-wrap: nowrap;
}

.wrapper-menu-contract .wrapper-menu-nav{
    justify-content: flex-end;
}
</style>
