import { getPolicyPrice } from '@/api/ApiClient.js'
import { getProductCategoryId } from '@/api/ApiClientUtils'
import { getters } from '@/state/store.js'
import landing from '@/config/constants/landing.js'

export async function calculateInsurancesRates(value, currentLanding, insurances) {
    var periodicity = 1;
    var discount_code = 0;
    return Promise.all(insurances.map(async insurance => {
        var product_category_id = getProductCategoryId(value, insurance)
        var product_id = getters.getProducts().filter((x) => currentLanding === landing.seguromultidispositivo.name ? x.product_type.identifier.split('tu')[1] === currentLanding 
            : x.product_type.identifier.split('tu')[1] === currentLanding && x.name.split(" ")[0].toLowerCase().includes('top') )[0].id;


        var params = {
            "product_id": product_id,
            "periodicity": periodicity,
            "discount_code": discount_code
        }

        if (currentLanding !== "seguropatinete") {
            params["product_category_id"] = product_category_id
        } else {
            params["purchase_price"] = value
        }

        const price = await getPolicyPrice(params);
        if (price == -1) {
            return {
                productId: product_id,
                error: true
            }
        } else {
            return {
                productId: product_id,
                price,
            };
        }
    }));
}
