<template>
    <transition name="">
        <b-modal ref="errorModalRef" :no-close-on-backdrop="true" centered hide-header hide-footer class="modal fade">
          <div class="modal-center">
            <a class="btn-close-modal pr-4 pointer" @click="hideModal" style="font-size:30px;"><mdi-icon icon="mdiClose" /></a>
            <div class="text-center">
              <a class="text-right pr-4" style="font-size:30px;"></a>                 
              <div class="col-12">
                  <br><h3><strong> {{message}}</strong></h3><br>
              </div>
            </div>
          </div>
        </b-modal>
    </transition>
</template>

<script>

export default {
  name: 'ErrorModal',
  data() {
        return {
          message: ""
        }
  },
  methods: {
    showModal(errorMessage) {
      this.message = errorMessage
      this.$refs.errorModalRef.show()
    },
    hideModal() {
      this.pushGTM('funnel', 'cancelar', 'recurrente')
      this.$refs.errorModalRef.hide()
      if(this.message == 'Le informamos que la solicitud ingresada no cumple con los requisitos para la contratación del Seguro que desea adquirir, por lo que no es posible continuar con el trámite requerido.'){
        this.$router.push({ name: 'home' })
      }
    },
  },

}
</script>

<style lang="scss">

</style>