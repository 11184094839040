<template>
    <section>
        <StoresComponent :showStoreList= "true" />
    </section>
</template>

<script>
import StoresComponent from "@/components/stores/StoresComponent";

export default { 
    name: 'Map',
    components: {
        StoresComponent
  },
}
</script>

<style lang="scss" scoped>

</style>
