<template>
    <section>
        <div id="offers" class="lazy-background">
            <splide id="offers-slider" :options="options">
                <splide-slide v-for="offer in offers" :key="offer.title">
                    <div class="item">
                        <div class="row">
                            <div class="col-lg-12 item_planduos">
                                <div class="item_planduos--info">
                                    <h3 class="title">{{offer.title}}</h3>
                                    <p class="description">{{offer.description}}</p>
                                    <div class="badget"><a href =""><img :src="getImgPath(offer.landingImageName)"></a></div>
                                </div>
                                <div class="item_planduos--img"><img :src="getImgPath(offer.offerImageName)"></div>
                            </div>
                        </div>	
                    </div>
                </splide-slide>
            </splide>
        </div>
    </section>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default {
    name: "SliderOffersComponent",
    props: ['currentLanding'],
    components: {
        Splide,
        SplideSlide
    },
    data() {
       return {
           offers: this.getOffers(),
           options: {
                pagination : false,
                perPage: 2,
                perMove: 1,
                breakpoints: {
                    700: {
                        perPage: 1,
                        pagination : true,
                    },
                    1000: {
                        perPage: 2,
                        pagination : false
                    }
                }
            }
        }
        
    },
    methods: {
        getOffers(){
            var offers = this.$t('sliderOffersComponent.offers')
            if (offers.length > 0) {
                let offersFiltered = offers.filter((x) => {return x.landing === this.currentLanding})[0]
                return offersFiltered.offers
            }
            return []
        },
        getImgPath(imageName) {
            return require('@/assets/slider-offers/'+ imageName)
        }
    },
    mounted(){
        var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));

        if ("IntersectionObserver" in window) {
            let lazyBackgroundObserver = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visible");
                        lazyBackgroundObserver.unobserve(entry.target);
                    }
                });
            });

            lazyBackgrounds.forEach(function(lazyBackground) {
                lazyBackgroundObserver.observe(lazyBackground);
            });
        }
    }
}
</script>

<style lang="scss" scoped>

#offers {
    padding: 500px 0 0px 0;
    margin-top: -450px;
}

.lazy-background.visible{
    background: url(~@/assets/slider-offers/franja-asc.png) 0 0 no-repeat;
    background-size: cover;
}

.item, 
.item .row {
    height: 100%;
}

.item .row {
    flex-direction: column;
    justify-content: space-between;
}

.item_planduos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
}
.item_planduos--info .badget {
    padding-left: 50px;
}
.item_planduos--img img {
    position: relative;
    display: block;
    width: auto;
    height: 250px;
    margin: 0 auto;
    top: 0px;
    margin-top: 2rem;
}

#offers-slider {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    position: relative;
}

#offers-slider .title {
    color: $white-color;
    font-size: 35px;
    font-weight: bold;
    margin-left: 50px;
}

#offers-slider .description {
    color: $white-color;
    font-size: 25px;
    font-weight: 300;
    margin-left: 50px;
}

@media (max-width: 700px){
    #offers-slider .title{
        font-size: 35px;
        
    }
    #offers-slider .description{
        font-size: 25px;
        padding-right: 20px;
        
    }
    .item_planduos--img img{
       height: 250px; 
    }
}

@media (max-width: 500px){
    #offers-slider .description{
        font-size: 20px;  
    }
    .item_planduos--img img{
        height: 200px;
    }
}

</style>

<style lang="scss">

#offers .splide {
    &__arrow {
        width: 0em;
        height: 0em;
    }
}

</style>