<template>
    <div id="container-order">
        <section v-if="invalidCode">
            <HeaderOrderComponent
                    :currentLanding="currentLanding"
                    :pageNumber="step"
                    :distributorLogo="landingData.logo_path"
            />

            <div class="d-flex flex-column align-items-center justify-content-center">
                <h4>El código introducido no es válido</h4>

                <router-link :to="{ name: 'discount', params: { landing: $route.query.landing_param } }" class="btn-orange mt-2">{{ $t('order.error.return') }}</router-link>
            </div>
        </section>
        <section v-else>
            <HeaderOrderComponent
                    :currentLanding="currentLanding"
                    :pageNumber="step"
                    :distributorLogo="landingData.logo_path"
            />
            <OrderDiscountTitle :currentLanding=this.currentLanding />
            <OrderPrice :currentLanding=this.currentLanding :pageNumber=this.step :policyData=policyData />
            <section class="insurance-container mt-4">
                <div class="container">

                    <CapitalSelector
                            v-if="currentLanding === landing.seguromultidispositivo.name"
                            :currentLanding=this.currentLanding
                            :policyData=policyData
                    />
                    <DevicePriceSelectorDiscount
                        :currentLanding=this.currentLanding
                        :policyData="policyData"
                        :code="code"
                        :distributorId="distributorId"
                        :step1BtnClicked="step1BtnClicked"

                        :min-price="codeData.min_value"
                        :max-price="codeData.max_value"
                        @devicePriceEntered="setPurchasePrice"
                    />
                </div>
            </section>
            <section class="payment-container mt-4">
                <div class="container">
                    <h4>{{$t('order.userInputs.client_data')}}</h4>
                    <UserInputsDiscount
                        :currentLanding = this.currentLanding
                        :policyData="policyData"
                        :userData="userData"
                        :step2BtnClicked="step2BtnClicked"
                        :code="code"
                    />
                </div>
            </section>
            <div class="container">
                <button type="button" class="btn-orange pulse-effect" @click="createPolicyBtnPressed" :disabled="loading">
                    <span v-if="loading">
                        <mdi-icon icon="mdiLoading" class="mr-3" spin/>
                       <span>{{ this.$t('order.button_loading') }}</span>
                    </span>
                    <span v-else>{{  this.$t('order.activate') }}</span>
                </button>
            </div>
            <ErrorModal
            ref="errorModalRef" />
        </section>
    </div>
</template>

<script>
import Vue from "vue";

import HeaderOrderComponent from "@/components/order/common-components/HeaderOrderComponent.vue";
import OrderDiscountTitle from "@/components/order/common-components/OrderDiscountTitle.vue";
import OrderPrice from "@/components/order/common-components/OrderPrice.vue";

import CapitalSelector from '@/components/order/step1/elements/CapitalSelector.vue';
import DevicePriceSelectorDiscount from '@/components/order/step1/elements/DevicePriceSelectorDiscount.vue';
import UserInputsDiscount from '@/components/order/step2/elements/UserInputsDiscount.vue';

import ErrorModal from "@/components/modals/ErrorModal.vue"
import { getProductCategoryId } from '@/api/ApiClientUtils.js'
import { getters } from '@/state/store.js'
import landing from '@/config/constants/landing.js'

/* API-REST */
import { getPolicyPriceParams, getSubmitPolicyParams } from '@/api/ApiClientParamsSetter.js'
import { getPolicyPrice, submitPolicy, getDiscountsInABoxLandingData, validateDiscountsInABoxCode, createPolicyPaymentMethod, markPaymentAsPaid } from '@/api/ApiClient.js'


export default {
    name: "OrderDiscountsBox",
    components: {
        HeaderOrderComponent,
        OrderDiscountTitle,
        OrderPrice,
        ErrorModal,
        CapitalSelector,
        DevicePriceSelectorDiscount,
        UserInputsDiscount
    },
    data() {
        return {
            isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
            step: 1,
            step1BtnClicked: false,
            step2BtnClicked: false,
            landingParam:this.$route.query.landing_param,
            min_price: 0,
            max_price: 1000,
            code: this.$route.query.code,
            distributorId: '',
            loading: false,
            policyData: {
                purchasePrice: this.$route.query.price,
                idCategory: this.$route.query.id_category,
                product_id: this.$route.query.id,
                store_id: -1,
                periodicity: {},
                policyPrice: "",
                isLoadingPolicyPrice: false,
                phone: "",
                product: {},
                reference: "",
                model: "",
                device_type: "",
                purchaseDate: "",
                device: "",
                capital: {},
                discountCode: this.$route.query.code,
                risks: [
                {
                    "device": "",
                    "purchase_price": ""
                }]
            },
            userData: {
                nif: "",
                name: "",
                email: "",
                postal_code: "",
                birth_date: "",
                state: "",
                town: "",
                colony: "",
                street: "",
                exterior_number: "",
                inner_number: "",
                card_number: "",
                card_expiration_month: "",
                card_expiration_year: "",
                card_cvv: "",
                iban: "",
                acceptance_type: 0,
                acceptance_type_2: 0,
                acceptance_coverages: 0
            },
            landing,
            invalidCode: false,
            landingData: {},
            codeData: {}
        }
    },
    watch:{
        'policyData.product'() { this.getPolicyPrice() },
        'policyData.periodicity'() { this.getPolicyPrice() },
        'policyData.capital'() { this.getPolicyPrice() },
        'policyData.purchaseDate'() {
            this.pushGTM('funnel', 'fecha_compra', 'paso1', this.policyData.purchaseDate, this.policyData.product.name)
        }
    },
    computed: {
        currentLanding: getters.currentLanding,
        buttonText(){
            switch (this.step) {
                case 1:
                    return this.$t('order.next_button_step_1')
                case 2:
                    return this.$t('order.next_button_step_2')
                default:
                    return ""
            }
        },
    },
    created(){
        if(!this.isEnableOrderProcess) {
            this.$router.push({ name: 'home' })
        }

        // We only allow yearly payments
        this.policyData.periodicity = 12;

        // Preselect the first product
        this.selectedInsurance = this.$t('calculatePriceComponent.insuranceBoxes.insurances')
                .filter((x) =>  x.landing === this.currentLanding)[0]
        this.policyData.product = this.selectedInsurance.insurances.filter((insurance) =>  insurance.product_id == this.policyData.product_id)[0]
    },
    mounted() {
        this.getLandingData()
                .then(() => this.getDiscountCodeData())
    },
    methods: {
        async getDiscountCodeData() {
            this.codeData = await validateDiscountsInABoxCode(this.code, this.distributorId)

            if (this.codeData.discount_validation !== 1) {
                this.invalidCode = true
            }

            this.policyData.store_id = this.codeData.store_id
            this.min_price = this.codeData.min_value
            this.max_price = this.codeData.max_value
        },
        setPurchasePrice(price){
            this.policyData.purchasePrice = price
            if(price >= this.min_price && price <= this.max_price){
                this.getPolicyPrice()
            }
        },
        setDiscountCode(discountCodeApplied){
            this.policyData.discountCode = discountCodeApplied
            this.getPolicyPrice()
        },
        async getPolicyPrice(){
            this.policyData.isLoadingPolicyPrice = true

            if (this.currentLanding !== landing.seguromultidispositivo.name) {
                this.policyData.idCategory = getProductCategoryId(this.policyData.purchasePrice, this.policyData.product)
            } else {
                if (!this.policyData.capital.amount) this.policyData.capital.amount = 7000;

                const categoryID = getProductCategoryId(this.policyData.capital.amount, this.policyData.product);
                this.policyData.capital.categoryID = categoryID;
                this.policyData.idCategory = categoryID;
            }

            const params = getPolicyPriceParams(this.currentLanding, this.policyData)
            const policyPrice = await getPolicyPrice(params)
            if(parseFloat(policyPrice) > 0){
                this.policyData.policyPrice = policyPrice
            }else{
                this.policyData.policyPrice = this.$t('calculatePriceComponent.insuranceBoxes.price_not_available')
            }

            this.policyData.isLoadingPolicyPrice = false
        },
        async createPolicy(){
            this.step = 3

            const params = getSubmitPolicyParams(this.currentLanding, this.policyData, this.userData)

            // UTM INFO
            const data = this.getUTMData();

            params.utm_source = data.utm_source;
            params.utm_medium = data.utm_medium;
            params.utm_campaign = data.utm_campaign;

            this.loading = true
            const response = await submitPolicy(params)

            if(response.isValidPolicy){

                var params2 = {
                    identifier: response.policy.identifier,
                    payment_identifier: response.policy.policy_payments[0].identifier,
                    origin: 'FRONTAL_DISCOUNT',
                    creation: 0,
                    hash: response.hash
                };
                await markPaymentAsPaid(response.policy.identifier, response.policy.policy_payments[0].identifier, []);
                const response2 = await createPolicyPaymentMethod(params2);
                if (response2.form) {
                    console.log('correct form');
                    this.submitResponseForm(response2);
                } else {
                    console.log('not correct form');
                    this.loading = false
                    this.$refs.errorModalRef.showModal(response2.error)
                }
            } else {
                this.loading = false
                this.$refs.errorModalRef.showModal(response.error)
            }
        },
        async getLandingData() {
            try {
                const landing = await getDiscountsInABoxLandingData(this.landingParam)

                this.distributorId = landing.distributor_id

                Vue.set(this.$data, 'landingData', landing)
            } catch {
                console.error("Error getting landing data")
            }
        },
        isValidPrice(price, min, max) {
            const hasMinPrice = min == 0 || !!min
            const hasMaxPrice = max == 0 || !!max

            if(!hasMinPrice && hasMaxPrice){
                return price <= max;
            }
            if(!hasMaxPrice && hasMinPrice){
                return price >= min;
            }

            if (!hasMinPrice && !hasMaxPrice) {
                return true
            }
            return price >= min && price <= max
        },
        createPolicyBtnPressed () {
            this.step1BtnClicked = true
            this.step2BtnClicked = true

            if(
                this.isValidOrderStep2(this.userData) &&
                this.isValidOrderStep1(this.currentLanding, this.policyData) &&
                this.isValidPrice(this.policyData.purchasePrice, this.min_price, this.max_price)
            ){
                this.pushGTM('funnel', 'confirmar_paso2', 'paso2', '', this.policyData.product.name)
                this.createPolicy()
            }
        },
        createElementFromHTML(htmlString) {
            var div = document.createElement('div');
            console.log('HTML Form')
            console.log(htmlString.trim())
            div.innerHTML = htmlString.trim();
            document.body.appendChild(div);
            return div.firstChild;
        },
        submitResponseForm(response) {
            this.createElementFromHTML(response.form).submit()
        },
    },
}
</script>

<style lang="scss" scoped>

.container {
    max-width: 600px;

}

.pulse-effect {
    width: 100%;
    transition: all 0.300s;
    animation: pulse 0.6s infinite linear alternate;
    margin-bottom: 40px;
}

.pulse-effect:hover {
   background: $red-hover;
}

@keyframes pulse{
    0%{
        transform: scale(1);
    }100%{
        transform: scale(1.05);
    }
}
.wrapper-menu .wrapper-menu-contract {
    flex-wrap: nowrap;
}

.wrapper-menu-contract .wrapper-menu-nav{
    justify-content: flex-end;
}
</style>

