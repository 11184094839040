 <template>
    <section class="frequent-questions">
        <div class="container">
            <div class="row">
                <p>
                    {{ $t('restrictions') }}
                </p>
            </div>
            <div class="row">
                <div class="col-12">
                    <section class="section-title">{{ $t('frequentQuestionsComponent.title') }}</section>
                </div>
            </div>
            <div class="row">
                <transition-group class="col-12" name="flip-list" tag="ul">
                    <div class="frequent-question" v-for="(frequentQuestion, questionIndex) in frequentQuestions" v-bind:key="frequentQuestion.question" :id="'faq-question-' + questionIndex" >
                        <div class="question" @click="setCollapsable(questionIndex)">
                            <div class="question-title">
                                <a href='javascript:void(0)'>{{frequentQuestion.question}}</a>
                            </div>
                            <div class="question-btn">
                                <a href='javascript:void(0)'>
                                    <img v-if="isActiveQuestion(questionIndex)" src='@/assets/frequent-questions/minus.png'>
                                    <img v-else src='@/assets/frequent-questions/plus.png'>
                                </a>
                            </div>
                        </div>
                        <!-- <div class="answer"><span v-html="frequentQuestion.answer"></span></div>-->
                        <div v-show="isActiveQuestion(questionIndex)" class="answer">
                            <div class="col-12">
                                <span v-html="frequentQuestion.answer"></span>
                            </div>
                        </div>
                    </div>  
                </transition-group>
            </div>
        </div>
         <ReportSinisterModal 
                    ref="reportSinisterRef"
                    :currentLanding='currentLanding' />
    </section>
</template>

<script>
import ReportSinisterModal from '../../components/modals/ReportSinisterModal.vue'

export default {
    name: "FrequentQuestionsComponent",
    props: ['currentLanding'],
    data(){
        return{
            frequentQuestions: this.getFrequentQuestions(),
            activeQuestions: [],
        }
    },
    components: { 
        ReportSinisterModal,
    },
    methods: {
        getFrequentQuestions() {
            var frequentQuestionsFiltered = this.$t('frequentQuestionsComponent.frequentQuestions').filter((x) => {return x.landing === this.currentLanding})[0]
            return frequentQuestionsFiltered.frequentQuestions
        },
        setCollapsable(questionIndex) {
            if(!this.isActiveQuestion(questionIndex)){
                this.activeQuestions.push(questionIndex)
                this.addClickEvent();
            }else{
                this.activeQuestions.splice(this.activeQuestions.indexOf(questionIndex), 1);
            }

        },
        isActiveQuestion(questionIndex) {
            if(this.activeQuestions.includes(questionIndex)) {
                return true
            }
            return false
        },
        addClickEvent() {
            var appAnchors = document.querySelectorAll(".show-report-sinister");
            for (let item of appAnchors) {
                item.addEventListener("click",(e) => {
                    e.preventDefault()
                    this.$refs.reportSinisterRef.showModal()
                }, false);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.frequent-questions {
    margin-top: 35px;
}
.frequent-questions .section-title{
    text-align: center;
    margin: 40px 0;

}


.frequent-question {
    padding: 10px 40px;
}
@media (max-width: 485px){
    .frequent-question {
        padding: 10px 10px;
    }
}
.question {
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    transition: transform 0.3s;
}

.question .question-title{
    width: 90%;
}
.question .question-btn{
    width: 9%;
    text-align: right;
    
}
.question a {
    color: $blue-color;
    font-size: 16px;
    font-weight: 500;
}




.answer {
    background: $gray-color-light;
    font-size: 16px;
    font-weight: 400;
    padding: 20px 40px 10px 40px; 
    
    
}



.flip-list-move {
  transition: transform 0.2s;
}

</style>  
