 <template>
    <section id="how-it-works">
        <div :class="['orangeBackground', {'orangeBackground--disabled-order-process': !isEnableOrderProcess}]"></div>
        <div class="container">
            <div class="row">
                <div class="col">
                  <h1 class="mt-5 text-center section-title">{{ $t('howWorksComponent.title_'+currentLanding) }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <p class="white"> {{steps.title}}</p>
                </div>
            </div>
            <div :class="`how-it-works_content ${this.$i18n.locale} ${this.currentLanding}`">
                <div class="content">
                    <div v-for="(step, stepIndex) in steps.steps" v-bind:key="step.id">
                        <div class="stepsBox mt-2">
                            <div class="content-description">
                                <div class="col-2">
                                    <p class="stepNumber">{{stepIndex + 1}}</p>
                                </div>
                                <div class="col-10">
                                    <h5 class="stepTitle">{{step.title}}</h5>
                                    <p class="stepDescription">{{step.description}}</p>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <div class="content_img"> 
                <!-- Switch landing sin animación-->
                    <img loading="lazy" :src="getHowWorksIcon()">
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: "HowWorksComponent",
    props: ['currentLanding'],
    data(){
        return{
            steps: this.getSteps(),
            isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
        }
    },
    methods: {
        getSteps() {
            var stepsFiltered = this.$t('howWorksComponent.steps').filter((x) => {return x.landing === this.currentLanding})[0]
            return stepsFiltered
        },
        getHowWorksIcon() {
            return require(`@/assets/how-works/${this.$i18n.locale}_${this.currentLanding}.png`)
        }
    }
}
</script>

<style lang="scss" scoped>

#how-it-works {
    min-height: 800px;
    
}
#how-it-works .how-it-works_content{ 
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
#how-it-works .how-it-works_content .content-description { 
    display: flex;
    flex-wrap: wrap;
}
#how-it-works .how-it-works_content .content {
    width: 59%;
}
#how-it-works .how-it-works_content .content_img {
    width: calc(41% - 30px);
    display: flex;
    align-items: center;
    margin-left: 30px;
}

#how-it-works .how-it-works_content .content_img img {
    width: 100%;
}

#how-it-works .how-it-works_content .content_img {
    width: calc(41% - 30px);
    display: flex;
    align-items: center;
    margin-left: 30px;
}

#how-it-works .how-it-works_content.pt.seguromovil .content_img {
    width: calc(41% - 125px);
    margin-left: 125px;
}

#how-it-works .how-it-works_content.pt.seguromovil .content_img img {
    width: auto;
}

@media (max-width: 980px){
    #how-it-works .how-it-works_content .content{
        width: 100%;
    }
    #how-it-works .how-it-works_content .content_img{
        display: none;
    }
}
   
.orangeBackground {
    position: absolute;
    z-index: -100;
    width: 100%;
    min-height: 1300px;
    margin-top: -125px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxMTUyIDkxMyIgZmlsbD0ibm9uZSI+CjxyZWN0IHdpZHRoPSI3MjUuOTkiIGhlaWdodD0iMTQ5NS4xIiB0cmFuc2Zvcm09Im1hdHJpeCgtMC43MzY2NzYgMC42NzYyNDYgMS45NDU0NDYgMC4zMjU3NzcgMzE0LjQ5NyAtMzkyLjI4OCkiIGZpbGw9InVybCgjcGFpbnQxX2xpbmVhcikiLz48cmVjdCB3aWR0aD0iMjMwLjE1NiIgaGVpZ2h0PSIxNDgxLjg4IiB0cmFuc2Zvcm09Im1hdHJpeCgtMC43MzY2NzYgMC42NzYyNDYgMS45NDU0NDYgMC4zMjU3NzcgLTUxLjYxODIgMjgxLjE5OSkiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcikiLz4KCjxyZWN0IHdpZHRoPSIyMzAuMTU2IiBoZWlnaHQ9IjE1OTIuMzkiIHRyYW5zZm9ybT0ibWF0cml4KC0wLjgzNjY3NiAwLjY3NjI0NiAxLjk0NTQ0NiAwLjMyNTc3NyAtMjQuNDMxNiA5OS43MTg4KSIgZmlsbD0idXJsKCNwYWludDJfbGluZWFyKSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyIiB4MT0iMTE1LjA3OCIgeTE9IjAiIHgyPSIxMTUuMDc4IiB5Mj0iMTQ4MS44OCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRjgyNzMzIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0ZGNTYzMiIvPgo8L2xpbmVhckdyYWRpZW50Pgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MV9saW5lYXIiIHgxPSIwIiB5MT0iMTQ5NS4xIiB4Mj0iNzI3LjUxNSIgeTI9IjE0OTUuMSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRkY1NjMyIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0Y4MjczMyIvPgo8L2xpbmVhckdyYWRpZW50Pgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50Ml9saW5lYXIiIHgxPSIwIiB5MT0iMTU5Mi4zOCIgeDI9IjIzMC42MzkiIHkyPSIxNTkyLjM4IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiNGRjU2MzIiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRjgyNzMzIi8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cgo=");
    background-position: center;
    background-size: cover;  
}

.orangeBackground--disabled-order-process {
        margin-top: 15px;
}

.section-title{
    color:$white-color
}

.stepsBox {
    background: $white-color;
    box-shadow: 0px 6px 7px rgba(73, 83, 113, 0.08);
    border-radius: 4px;
    width: 100%;
    min-height: 102px;
    height: auto;
    margin-bottom: 2px;
    border: 1px solid #f4f4f4;
}

.stepNumber {
    font-weight: 900;
    font-size: 64px;
    line-height: 90px;
    margin-top: 0px;
    display: block;
    text-align: right;
    color: $blue-color-light;
    margin-left: 10px;
}

.stepTitle {
    font-weight: 600;
    margin-top: 7px;
    font-size: 18px;
    line-height: 20px;
    color: $red-color;
}

.stepDescription {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $blue-color-light;
    padding-right: 25px;
}

</style>  
