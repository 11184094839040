<template>
    <section v-if="type === 'standard' || type === 'staggered'">
        <div class="row">
            <div class="col text-center">
                <h1 class="mt-5 section-title">{{header.title}}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <p>{{ header.title_question }}</p>
            </div>
        </div>
        <div class="range-wrap">
            <!-- Slider standard-->
            <output v-if="type === 'standard'" class="bubble"></output>
            <input v-if="type === 'standard'" ref="SliderRef" type="range" class="range" :min='minValue' :max='maxValue' :step='stepValue' :value='sliderValue'>

            <!-- Slider staggered -->
            <div class="outputs-inline">
                <div v-for="(value, index) in arrayValues" v-bind:key="value.key">
                    <output v-if="type === 'staggered'" :id="'bubble_' + index" :ref="'bubble_' + index" class="bubble"></output>
                </div>
            </div>
            <input v-if="type === 'staggered'" ref="SliderRef" type="range" class="range" min='0' :max='arrayValues.length - 1' step='1' :value='sliderValue'>
        </div>
    </section>
</template>

<script>

export default {
    name: "SliderPrice",
    props: {
        currentLanding: {
            type: String
        },
        type: {
            type: String,
            required: true,
            validator: value => ['standard', 'staggered'].indexOf(value) >= 0
        },
        minValue: {
            type: String,
            required: false
        },
        maxValue: {
            type: String,
            required: false
        },
        arrayValues:{
            type: Array,
            required: false
        },
        stepValue: {
            type: String,
            required: false
        },
        initialValue: {
            type: String,
            required: false
        },
        lessBelowText: {
            type: Boolean,
            default: false
        },
        moreAboveText: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            header: this.getHeaderTexts(),
            sliderValue: 0
        }
    },
    methods: {
        initConfig () {
            
            const allRanges = document.querySelectorAll(".range-wrap");
            allRanges.forEach(wrap => {
                const range = wrap.querySelector(".range");
                const bubble = wrap.querySelector(".bubble");
                range.addEventListener("input", () => {
                    this.updateProgressBar(range.value)
                    if(this.type == "standard"){
                        this.setDynamicBubble(range.value, bubble);
                    }
                });
                range.addEventListener("mouseup", () => {
                    this.sliderDropped(range)
                });
                range.addEventListener("touchend", () => {
                    this.sliderDropped(range)
                })
                this.initSlider(bubble)
            });
        },
        sliderDropped(range){
            this.emitToParent(range.value)
        },
        initSlider(bubble){
            if(this.type == "standard"){
                if(this.initialValue && this.initialValue != "" && parseInt(this.initialValue) >= parseInt(this.minValue) && parseInt(this.initialValue) <= parseInt(this.maxValue)){
                    this.sliderValue = this.initialValue
                }else{
                    this.sliderValue = this.minValue
                }
            }else if(this.type == "staggered") {
                if(this.initialValue && this.initialValue != ""){
                    var index = this.arrayValues.findIndex(x => x === this.initialValue)
                    if(index >= 0){
                        this.sliderValue = index
                    }
                }
            }
            this.initBubbles(this.sliderValue, bubble)
            this.updateProgressBar(this.sliderValue)
            this.emitToParent(this.sliderValue)
        },
        initBubbles(range, bubble){
            if(this.type == "standard"){
                this.setDynamicBubble(range, bubble);
            }
            else if(this.type == "staggered"){
                this.arrayValues.forEach((value, index) => {
                    var bubbleReference = "bubble_" + index
                    const bubble = Array.isArray(this.$refs[bubbleReference]) ? this.$refs[bubbleReference][0] : this.$refs[bubbleReference]
                    this.setStaticBubble(index, value, bubble)
                })
            }
        },
        setDynamicBubble(value, bubble) {
            const newVal = Number(((value - parseInt(this.minValue)) * 100) / (parseInt(this.maxValue)  - parseInt(this.minValue) ));
            if(value == this.maxValue && this.moreAboveText){
                bubble.innerHTML = `${this.$t('calculatePriceComponent.sliderPrice.more_price_text')} $ ${parseInt(this.maxValue) - parseInt(this.stepValue)}`
            }else if(value == this.minValue && this.lessBelowText){
                bubble.innerHTML = `${this.$t('calculatePriceComponent.sliderPrice.less_price_text')} $ ${parseInt(this.minValue) + parseInt(this.stepValue)}`
            }else {
                bubble.innerHTML = "$ " + value;
            }
            bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
        },
        setStaticBubble(index, value, bubble) {
            const newVal = Number((index * 100) / (this.arrayValues.length - 1));
            bubble.innerHTML = "$ " + value;
            bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
        },
        updateProgressBar(value){
            var valPercent = (value  - parseInt(this.$refs.SliderRef.min)) / (parseInt(this.$refs.SliderRef.max) - parseInt(this.$refs.SliderRef.min));
            var style = 'background-image: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop('+ valPercent+', #FF4C00), color-stop('+ valPercent+', #f5f6f8));';
            this.$refs.SliderRef.style = style;
        },
        emitToParent(value){
            if(this.type == "standard"){
                this.$emit('sliderStop', parseInt(value))
            }else{
                this.$emit('sliderStop', parseInt(this.arrayValues[value]))
            }
        },
        getHeaderTexts(){
            var headerData = this.$t('calculatePriceComponent.sliderPrice.header')
            let headerDataFiltered = headerData.filter((x) => {return x.landing === this.currentLanding})[0]
            return headerDataFiltered.data
        }
    },
    mounted(){
        this.initConfig()
    }
}

</script>

<style lang="scss" scoped>

.outputs-inline {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between
}

.range-wrap {
  position: relative;
  margin: 0 auto 3rem;
  width: 80%;
}

.range {
  -webkit-appearance: none;
  background-color: #f5f6f8;
  height: 8px;
  width: 100%;
  margin: 10px auto;
  border-radius: 4px;
}

.range:focus {
  outline:none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background-image: url('../../assets/calculate-price/slider-circle.png');
  background-size: cover;
}

.bubble {
  color: $black-color;
  padding: 4px 7px;
  position: relative;
  margin-bottom: 10px;
  border-radius: 4px;
  border-style: solid;
  border-color: $orange-color;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
}

.bubble::after {
    content: "";
    position: absolute;
    top: 33px;
    border-width: 10px;
    border-color: $orange-color transparent transparent transparent;
    border-style: solid;
    left: 50%;
    margin-left: -9px;
}


@media (max-width: 400px){
    .bubble {
        padding: 4px;
        font-size: 0.8rem;
        width: max-content;
    }
    .bubble::after {
        top: 30px;
    }
}
</style>

