import { uuid } from 'vue-uuid';

// TODO Get from env
var measurementId = process.env.VUE_APP_MEASUREMENT_PROTOCOL_ID || ''
var apiSecret = process.env.VUE_APP_MEASUREMENT_PROTOCOL_API_SECRET || ''

/**
 * Sends a request to the GA Measurement Protocol endpoint
 * 
 * @param {String|Object} body 
 * @returns Promise
 */
function sendMeasurementProtocolRequest (body) {
  let bodyStr = ''
  if (typeof body === 'string') {
    bodyStr = body
  } else if (typeof body === 'object') {
    bodyStr = JSON.stringify(body)
  }

  return fetch(
    `https://www.google-analytics.com/mp/collect?api_secret=${apiSecret}&measurement_id=${measurementId}`,
    {
      method: 'POST',
      body: bodyStr
    }
  )
}

/**
 * Sends a request 
 * 
 * @param {*} params 
 * @returns 
 */
function sendCollectRequest (params) {
  const clientId = params.client_id
  const userId = params.user_id
  const hashedEmail = params.events[0].params.email_dimension
  const eventName = params.events[0].name

  return fetch(
    `https://www.google-analytics.com/collect?v=1&t=event&tid=UA-117572693-1&cid=${clientId}&ec=Ecommerce&ea=${eventName}&el=-&uid=${userId}&cd4=${hashedEmail}`,
    {
      method: 'POST'
    }
  )
}

function getPolicyNameForGTag (id) {
  let aux = "";

  if (id == 1 || id == 2 || id == 3) {
      aux = "seguro_movil";
  }
  if (id == 5 || id == 6 || id == 7 || id == 18) {
      aux = "seguro_patinete";
  }
  if (id == 9) {
      aux = "seguro_multidispositivo";
  }
  if (id == 8) {
      aux = "extension_de_garantia";
  }
  if (id == 15 || id == 16 || id == 17) {
      aux = "seguro_portatil";
  }
  return aux;
}

export function sendPolicyCreatedAnalyticsRequest (policy) {
  const params = {
    tipo_seguro:  getPolicyNameForGTag(policy.product_id), //Tipo de seguro contratado
    tipo_pago: policy.payment_method_type_text, //Tipo de pago seleccionado
    valor_movil: policy.purchase_price, // Valor del móvil asegurado
    valor_prima: policy.price_total, //Valor del la prima
    event_category:"Ecommerce",
    event_action: 'poliza_emitida',
    event_label:"-",
    email_dimension: uuid.v5(policy.email, '65f9af5d-f23f-4065-ac85-da725569fdca') // dirección de email del usuario hasheado
  }

  const event = {
      client_id: policy.GA_client_id,
      user_id: policy.GA_user_id,
      timestamp_micros: (new Date()).getTime() * 1000,
      user_properties: { u_id: { value: policy.GA_user_id } },
      non_personalized_ads: false,
      events: [{ name: 'poliza_emitida', params }]
  }

  return Promise.all([sendMeasurementProtocolRequest(event), sendCollectRequest(event)])
}
