<template>
    <splide id="takeNowSlider" :options="options">
        <splide-slide v-for="slide in slides" :key="slide.titulo">
            <div class="item">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div class="slider-text" >
                            <h1 v-html="slide.titulo"/>
                            <div class="row flex-column" v-if="isEnableOrderProcess">
                                <div v-html="slide.previo_precio" class="col"></div>
                                <img loading="lazy" v-show="isLoading" src='@/assets/loader.gif' height=20 style='margin-right:10px;margin-top:5px;'>
                                <div v-show="!isLoading" ref="product_price" class="price col"></div>
                                <div v-html="slide.post_precio" class="col mb-4"></div>
                                <div>
                                    <button @click="emitEventToParent(slide.link)" class="btn-orange">{{slide.boton}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="diapo" :class="[`${currentLanding}`]" :style="{'background-image': 'url(' + getImgPath(slide.imagen) + ')'}">
                            <img v-if="currentLanding == landing.seguropatinete.name && !isMxBroker()" src="@/assets/take-now-slider/asegrup.png" class="img-powered">
                            <img v-if="currentLanding !== landing.seguropatinete.name && !isMxBroker()" :src="getPowerLogo()" class="img-powered">
                        </div>
                        <!--<div class="diapo" id="diapositiva-dinamica' . $cuenta . '" style="z-index:0">
                            <img src="@/assets/take-now-slider/helvetia.png" class="img-powered" width=50>
                        </div>
                        <lottie-animation
                            path="@/assets/take-now-slider/slide_movil_vector.json"
                            :loop="true"
                            :autoPlay="true"
                        /> -->
                    </div>
                </div>
            </div>
        </splide-slide>
    </splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
//import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { calculateInsurancesRates } from '@/components/calculate-price/js/calculate-insurances-rates.js'
import landing from '@/config/constants/landing';
import { getters } from '@/state/store.js'

export default {
    name: "TakeNowSliderComponent",
    props: ['currentLanding'],
    components: {
        Splide,
        SplideSlide,
        //LottieAnimation
    },
    data() {
       return {
           landing,
           slides: this.getSlides(),
            isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
           isLoading: false,
           //https://splidejs.com/options/
           options: {
                type: 'loop',
                pagination : false,
                perPage: 1,
                perMove: 1,
                height: 506,
                heightRatio: 506 / 1109,
                autoHeight: true
                //autoWidth: true
                //fixedHeight: 200
                //autoHeight: true,
                //widthRatio: 0.3
            }
        }
    },
    methods: {
        getSlides(){
            var slides = this.$t('takeNowSliderComponent.slides')
            let slidesFiltered = slides.filter((x) => {return x.landing === this.currentLanding})[0]
            let activeSlides = slidesFiltered.slides.filter((x) => {return x.active === true})
            const urlParts = activeSlides[0].link.split('&')
            urlParts[1] = `id=${this.getProductId()}`
            activeSlides[0].link = urlParts.join('&');
            return activeSlides
        },

        getPowerLogo() {
            return require(`@/assets/take-now-slider/${this.$i18n.locale}_slider_power.png`)
        },
        getImgPath(imageName) {
            return require('@/assets/take-now-slider/'+ imageName)
        },
        getInsurances(){
            var insurancesFiltered = this.$t('calculatePriceComponent.insuranceBoxes.insurances').filter((x) => {return x.landing === this.currentLanding})[0]
            return insurancesFiltered.insurances
        },
        async calculate(){
            this.isLoading = true
            var insurances = this.getInsurances()
            var insurancesPrices;
            if(this.currentLanding === landing.seguromultidispositivo.name){
                insurancesPrices = await calculateInsurancesRates(300, this.currentLanding, insurances)
            }else{
                insurancesPrices = await calculateInsurancesRates(1, this.currentLanding, insurances)
            }
            var productPrices = []
            insurancesPrices.forEach((insurance, index) => {
                if(insurance.price){
                    productPrices.push(insurance.price)
                }
                if(index == insurancesPrices.length - 1){
                    this.setPrice(productPrices)
                }
            })
        },
        setPrice(productPrices){
            this.isLoading = false
            var productReference = "product_price"
            const priceBox = Array.isArray(this.$refs[productReference]) ? this.$refs[productReference][0] : this.$refs[productReference]
            if (typeof(priceBox) != "undefined") {
                if(productPrices.length > 0){
                    priceBox.innerHTML = `$ ${productPrices.sort()[0]}`
                }else{
                    priceBox.innerHTML = this.$t('calculatePriceComponent.insuranceBoxes.price_not_available')
                }
            }
        },
        emitEventToParent(link) {
            this.$emit('goToOrderStep', link)
        },
        isMxBroker() {
            return this.$i18n.locale == 'mx'
        },
        getProductId(){
            var product = getters.getProducts().filter((x) => this.currentLanding === landing.seguromultidispositivo.name ? x.product_type.identifier.split('tu')[1] === this.currentLanding 
            : x.product_type.identifier.split('tu')[1] === this.currentLanding && x.name.split(" ")[0].toLowerCase().includes('top') )
            console.log(this.currentLanding);
            console.log(product);
            return product[0].id;
        }
    },
    mounted() {
        this.calculate()
    }
}
</script>

<style lang="scss" scoped>

.slider-text {
    position: absolute;
    left: 200px;
    color: $white-color;
    top: 30%;
    z-index: 2;
}

.slider-text .btn-orange {
    padding: 10px 45px;
    font-weight: 600;
    border: 1px solid $orange-color;
    font-size: 1rem;
}
.slider-text .btn-orange:hover{
    background: $orange-hover;
    color: $white-color;
    text-decoration: none;
}

.slider-text h1 {
    font-size: 3rem;
}

.slider-text .price {
    font-size: 3.75rem;
    font-weight: bold
}

.slider-text .price::after {
    font-size: 1rem;
    margin-left: 5px;
    font-weight: 100;
}

.diapo {
    width: 100%;
    height: 65vh;
    padding-top: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    position: relative;

}
.seguropatinete{
    background-position: bottom;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.seguroportatil,
.extensiondegarantia{
    background-position: center;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}
.seguroportatil .img-powered,
.seguropatinete .img-powered,
.extensiondegarantia .img-powered{
    transform: matrix(-1, 0, 0, 1, 160, 0);
}

.img-powered {
    width: 130px!important;
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 2000;
}

@media (max-width: 845px){
    .slider-text {
        left: 100px;
    }
    .slider-text h1 {
        font-size: 1.6rem;
        max-width: 90%;
    }
}
@media (max-width: 580px){
    .slider-text {
        left: 50px;
        top: 20%;
        width: 85%;
    }
}
@media (max-width: 1140px){
    .slider-text {
        top: 10%;

        h1 {
            font-size: 2rem;
        }
    }
    .diapo .img-powered {
        top: auto;
        right: 10px;
        bottom: 20px;
    }
}

@media (max-width: 375px){
    .slider-text .price {
        font-size: 2.4rem;
        font-weight: bold
    }

    .slider-text .btn-orange {
        padding: 10px 20px;
    }

    .diapo {
        min-height: 500px;
    }
    .diapo .img-powered {
        top: auto;
        bottom: 20px;
    }
}

</style>

<style lang="scss">
#takeNowSlider .splide {
    &__arrow {
        width: 0em;
        height: 0em;
    }
}
</style>