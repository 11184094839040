<template>
  <section class="insurance-container">
    <div class="container">
        <ProductSelector v-if="currentLanding != landing.seguromultidispositivo.name"
          :currentLanding = this.currentLanding
          :policyData = policyData
          :step1BtnClicked="step1BtnClicked" />  
        <CapitalSelector v-if="currentLanding == landing.seguromultidispositivo.name"
          :currentLanding = this.currentLanding
          :policyData = policyData /> 
        <DevicePriceSelector
          :currentLanding = this.currentLanding
          :policyData = policyData
          :step1BtnClicked="step1BtnClicked"
          v-on:devicePriceEntered= "setPurchasePrice" /> 
        <PaymentPeriodicity 
          v-if="currentLanding != landing.extensiondegarantia.name"
          ref="paymentComponent"
          :currentLanding = this.currentLanding
          :policyData="policyData" />  
        <BottomInputs 
          :currentLanding = this.currentLanding
          :policyData="policyData"
          :step1BtnClicked="step1BtnClicked"
          v-on:discountCodeApplied = "setDiscountCodeApplied" />                 
    </div>
  </section>
</template>

<script>
/* Components */
import ProductSelector from './elements/ProductSelector.vue';
import CapitalSelector from './elements/CapitalSelector.vue';
import DevicePriceSelector from './elements/DevicePriceSelector.vue';
import PaymentPeriodicity from './elements/PaymentPeriodicity.vue';
import BottomInputs from './elements/BottomInputs.vue';

/* Constants */
import landing from '@/config/constants/landing.js'

export default { 
  props: {
    currentLanding: {
      type: String
    },
    policyData: {},
    step1BtnClicked: {
      type: Boolean
    }
  },
  components: {
    ProductSelector,
    CapitalSelector,
    DevicePriceSelector,
    PaymentPeriodicity,
    BottomInputs
  },
  data() {
    return {
        landing
      }
  },
  methods: {
    setPurchasePrice(price){
      if(this.policyData.purchasePrice != price){
        this.$emit('purchasePriceEntered', price)
      }
    },
    refreshPaymentPeriodicityComponent(){
      this.$refs.paymentComponent.checkAnualPrice();
    },
    setDiscountCodeApplied(discountCodeApplied){
      this.$emit('discountCodeApplied', discountCodeApplied)
    }
  }
}

</script>

<style lang="scss">

.insurance-container .container{
  max-width: 600px;
}

</style>
