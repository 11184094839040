<template>
    <div class="container d-sm-block ">
        <div class="row insuranceInfo" >
            <div class="col-4 text-center p-1" v-for="column in element.columns" v-bind:key="column.column">
                <img class="d-block mx-auto" :src="getImgPath(column.imageName)"><span class="text-center">{{column.text}}</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "TopInfo",
    props: ['currentLanding'],
    data(){
        return{
            element: this.getElement()
        }
    },
    methods: {
        getElement(){
            var topInfo = this.$t('calculatePriceComponent.topInfo')
            let insuranceInfo = topInfo.filter((x) => {return x.landing === this.currentLanding})[0]
            return insuranceInfo
        },
        getImgPath(imageName) {
            return require('../../assets/icons/'+ imageName)
        }
    }
}
</script>

<style lang="scss" scoped>

.insuranceInfo {
    background: $white-color;
    box-shadow: 0px 13px 18px rgba(73, 83, 113, 0.19);
    border-radius: 10px;
    padding: 15px ;
    height: auto;
}

@media (max-width: 580px){
    .insuranceInfo{
        font-size: 0.8rem;
        padding: 15px 0;
    }
}

.insuranceInfo img {
    margin-bottom: 10px;
}

.insuranceInfo a {
    text-decoration: none;
}

</style>
