
<template>
    <div class="kind-insurances">
        <div class="kind-insurance-title"><span class="kind-insurance-text multi-elige-capital">{{$t('order.capitalSelector.choose_capital')}}</span></div>
        <div class="kind-insurance-elige" v-for="capital in capitals" v-bind:key="capital.amount.id">
            <div>
                <span class="kind-insurance-text">{{$t('order.capitalSelector.capital_amount', { amount: capital.amount })}}</span>
            </div>
            <div>
                <label class="switch" tyle="text-transform:uppercase" :for="'amount_' + capital.amount">
                    <input type="radio" :id="'amount_' + capital.amount" :ref="'amount_' + capital.amount" name="amount" :value="capital" v-model="policyData.capital" :checked="capital.categoryID == policyData.idCategory">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import { getProductCategoryId } from '@/api/ApiClientUtils.js'
import sliderParams from '@/config/constants/sliderConfig.js'

export default {
    name: "CapitalSelector",
    props: {
        currentLanding: {
            type: String
        },
        policyData: {
            type: Object
        },
    },
    data() {
        return {
            capitals: this.capitalsAvailable(),
            sliderParams
        }
    },
    methods: {
        capitalsAvailable(){
            var capitals = []
            let sliderValues = sliderParams[this.currentLanding].arrayValues
            sliderValues.forEach(value => {
                var category_id = getProductCategoryId(value, this.policyData.product)
                var capital = {
                    amount: value,
                    categoryID: category_id
                }
                capitals.push(capital)

                if(category_id == this.policyData.idCategory){
                    this.policyData.capital = capital
                }
            })
            
            return capitals;
        }
    },
    mounted(){
        this.capitalsAvailable()

        if (this.policyData.idCategory) {
            const currentCapital = this.capitals.find(cap => cap.categoryID === this.policyData.idCategory)
            if (typeof currentCapital !== 'undefined') {
                this.$refs[`amount_${currentCapital?.amount}`][0].click()
            }
        } else {
            this.$refs[`amount_${this.capitals[0].amount}`][0].click()
        }
    }
}

</script>

<style lang="scss">

.kind-insurances {
    margin: 15px 0;
    background-color: #e9ecef;
    padding: 1rem;
}

.kind-insurances .kind-insurance-title{
    margin: 16px 0;
    font-weight: 800;
}

.kind-insurances .kind-insurance-elige{
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.kind-insurances .kind-insurance-elige .kind-insurance-text{
    font-weight: 800; 
}

.kind-insurances .kind-insurance-elige input:checked + .slider {
    background-color: $green-color-light;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 23px;
  
}

/* Hide default HTML checkbox */
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 3px;
  background-color: $white-color;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>

