<template>
    <div id="container-order">
        <section>
            <HeaderOrderComponent
                :currentLanding= this.currentLanding
                :distributorLogo="this.$route.query.logo_path"
            />
             <div class="container main-box">
                <div class="col-12">
                    <div class="row section-content">
                        <div class="col-2">
                            <img height="45" :src="getLadingIcon()" align="left">
                        </div>
                        <h2 class="col-8 section-title pageTitle">{{ $t('order.ok.text1') }}</h2>
                        <div class="col-2">
                            <img height="45" :src="getManufacturerIcon()" align="left">
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <p class="error-text" v-html="$t('order.ok.text2', {name: getInsuranceName() })"></p>
                <p class="error-text">{{ $t('order.ok.text3', { policyNumber }) }}</p>
                <p class="error-text">{{ `${$t('order.ok.text4')} ${$t('order.ok.'+currentLanding)}` }}</p>
                <p><img height="45" :src="getTeamIcon()"></p>
                <img height="45" :src="this.$route.query.logo_path" class="mb-3"/>
            </div>
            <div class="container">
                <router-link to="/" class="btn-orange pulse-effect">{{ $t('order.ok.text5') }}</router-link>
            </div>
        </section>
    </div>
</template>
<script>

/* API-REST */
import { getPolicy} from '@/api/ApiClient.js'

import HeaderOrderComponent from "@/components/order/common-components/HeaderOrderComponent.vue";
import { getters } from '@/state/store.js'
import landing from '@/config/constants/landing.js'

import { sendPolicyCreatedAnalyticsRequest } from '@/utils/analytics/measurementProtocol'

export default {
    components: {
        HeaderOrderComponent
    },
    computed: {
        currentLanding: getters.currentLanding,
        policyNumber () {
            return typeof this.policy.number !== 'undefined' ? this.policy.number : ''
        }
    },
    data() {
        return {
            policy: {},
            isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
        }
    },
    methods: {
        getInsuranceName() {
            switch (this.currentLanding) {
                case landing.seguromovil.name:
                    return 'Celular';
                case landing.seguromultidispositivo.name:
                    return 'Multidispositivo';
                case landing.seguroportatil.name:
                    return 'Laptop';
                default:
                    return 'Celular';
            }
        },
        getLadingIcon() {
            //return require(`@/assets/header/${this.currentLanding}.png`)
        },
        getTeamIcon() {
            try {
                return require(`@/assets/logo/${this.$i18n.locale}_${this.currentLanding}.svg`)
            } catch (e) {
                return require(`@/assets/logo/${this.$i18n.locale}_${this.currentLanding}.png`)
            }
        },
        getManufacturerIcon() {
            return require(`@/assets/icons/fabricante.png`)
        },
        async getPolicyId(){
           this.policy = await getPolicy(this.$route.query.policy_id)
        }
    },
    created() {
        if(!this.isEnableOrderProcess) {
            this.$router.push({ name: 'home' })
        }
        this.url = '/order'+window.location.search;
    },
    mounted() {
        if (typeof this.$route.query.policy_id == 'undefined') {
            this.$router.push('/');
        }else{
            this.getPolicyId()
              // MX-229
              .then(() => {
                if (typeof this.policy === 'object' && Object.keys(this.policy).length > 0) {
                    sendPolicyCreatedAnalyticsRequest(this.policy)
                } else {
                    console.warn('Missing policy')
                }
              })
        }
    }
}
</script>

<style lang="scss" scoped>

.container {
    max-width: 600px;
    text-align: center;

    .row {
        justify-content: center;
        margin-bottom: 30px;
    }

    .error-text {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: $blue-color-light;
        padding-right: 25px;

        &--orange {
            color: $orange-color;
            margin-bottom: 30px;
        }
    }

    .section-title {
        font-weight: 600;
        margin-top: 7px;
        font-size: 18px;
        line-height: 20px;
        color: $blue-color-light;
        padding: 15px 0;
    }

    a.btn-orange {
        -webkit-appearance: button;
        -moz-appearance: button;
        appearance: button;
        background: $gray-color;
        border-color: $gray-color;
        text-decoration: none;

        &:hover {
            background: $gray-color-dark;
            border-color: $gray-color-dark;
        }
    }
}

.pulse-effect {
    width: 100%;
    transition: all 0.300s;
    animation: pulse 0.6s infinite linear alternate;
    margin-bottom: 40px;
}

.pulse-effect:hover {
   background: $red-hover;
}

@keyframes pulse{
    0%{
        transform: scale(1);
    }100%{
        transform: scale(1.05);
    }
}

</style>
