<template>
    <div class="form-group kind-insurance">
        <div class="kind-insurance-title">{{$t('order.productSelector.kind_insurance')}}</div>
        <div class="container">        
            <div class="row">
                <div class="check" v-for="product in selectedInsurance.insurances" v-bind:key="product.id">
                    <div class="custom-control custom-checkbox mt-1 col-4">
                        <input type="radio" :id="'product_id_' + product.product_id" name="product_id" :value="product" class="custom-control-input" v-model="policyData.product" :checked="product.product_id == policyData.product_id">
                        <label class= "custom-control-label" :for="'product_id_' + product.product_id">{{product.name}}</label> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ProductSelector",
    props: {
        currentLanding: {
            type: String
        },
        policyData: {}
    },
    data() {
        return {
            selectedInsurance: ""
        }
    },
    methods: {
        initProductSelector(){
            var insurancesFiltered = this.$t('calculatePriceComponent.insuranceBoxes.insurances').filter((x) => {return x.landing === this.currentLanding})[0]
            this.selectedInsurance = insurancesFiltered
            this.policyData.product = this.selectedInsurance.insurances.filter((insurance) => {return insurance.product_id == this.policyData.product_id})[0]
        }
    },
    created() {
        this.initProductSelector()
    }
}

</script>

<style lang="scss">

.kind-insurance {
    margin-top: 1rem;
}

.kind-insurance .kind-insurance-title {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

.kind-insurance .container .check {
    width: 25%;
}

.custom-control-label {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

.custom-control-input:checked~.custom-control-label::before {
    color: $white-color;
    border-color: $orange-color !important;
    background-color: $orange-color !important;
}


@media (max-width: 400px){
    .kind-insurance .container .check{
        width: 50%;
    }
}
</style>
