const coordinates = {}

if (process.env.VUE_APP_COUNTRY_CODE == 'it') {
    coordinates['latitude'] = 40.9992734
    coordinates['longitude'] = 14.1841072
    coordinates['zoom'] = 6
} else if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
    coordinates['latitude'] = 39.0065467
    coordinates['longitude'] = -7.7784604
    coordinates['zoom'] = 6
} else if (process.env.VUE_APP_COUNTRY_CODE == 'mx') {
    coordinates['latitude'] = 25.2237018
    coordinates['longitude'] = -102.7033314
    coordinates['zoom'] = 5
} else {
    coordinates['latitude'] = 40.9992734
    coordinates['longitude'] = 14.1841072
    coordinates['zoom'] = 6
}

module.exports = coordinates