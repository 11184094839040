<template>
    <section style="min-height: 800px;">
        <h1>{{option}}</h1>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="page-title">{{ $t('contact.title') }}</h2>
                </div>
            </div>
        </div>
        <div class="orangeBackground" style="margin-top:0px;">
            <img src="@/assets/bgNaranja.svg" class="img-fluid">
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <h3 class="titulo mt-5 white text-center"> {{ $t('contact.question') }}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <p class="description white"> {{ $t('contact.message') }} </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 offset-md-3">
                    <form class="container" @submit.prevent="submitSendForm" id="form-distribuidor">
                        <span>{{ $t('contact.formLabel1') }}</span>
                        <select type='text' name='asunto' class='form-control' required id="asunto-contacto" v-model="selectedOption">
                            <option v-for="listValue in getValueList()" :value="listValue" :key="listValue.key">
                                {{listValue}}
                            </option>
                        </select>
                        <br>

                        <span>{{ $t('contact.formLabel2') }}</span>
                        <input v-model="name" :class = "validateName()?'valid':'notValid'" type='text' name='nombre' class='form-control' id='nombre-contacto'>

                        <br>
                        <span>{{ $t('contact.formLabel3') }}</span>
                        <input v-model="email" :class = "validateEmail()?'valid':'notValid'" type='text' name='email' class='form-control' id='email-contacto'>

                        <br>
                        <span>{{ $t('contact.formLabel4') }}</span>
                        <input v-model="phone" :class = "validatePhone()?'valid':'notValid'" type='text' name='telefono' class='form-control' id='telefono-contacto'>

                        <br>
                        <span>{{ $t('contact.formLabel5') }}</span>
                        <textarea v-model="comments" :class = "validateComments()?'valid':'notValid'" class='form-control' name='comentario' style='min-height:200px;' id='comentarios-contacto'></textarea>

                        <br>
                        <button class="btn-blue" style='width:100%'>{{ $t('contact.sendButton') }}</button>
                    </form>
                </div>
            </div>
        </div>

        <ThanksModal 
            ref="thanksRef" />

        <ErrorModal 
            ref="errorModalRef" />
    </section>    
</template>

<script>
import { getContactmParams } from '@/api/ApiClientParamsSetter.js'
import { submitContact } from '@/api/ApiClient.js'
import ThanksModal from '@/components/modals/ThanksModal.vue'
import ErrorModal from "@/components/modals/ErrorModal.vue"

export default {
  name: 'Contact',
  data() { 
    return {
        name: "",
        email: "",
        phone: "",
        comments: "",
        selectedOption: this.$route.query.opt,
        isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
    }
  },  
  components: {
    ThanksModal,
    ErrorModal
  },
  methods: {
    getValueList() {
        var valuesList = [this.$t('callRequestModal.sinister'), this.$t('callRequestModal.distributor'), this.$t('callRequestModal.client_service')]
        if (this.isEnableOrderProcess) {
            valuesList.unshift(this.$t('callRequestModal.new_insurance'))
        }
        return valuesList
    },
    validateName() {
      return this.isValidTextField(this.name, 4)
    },
    validateEmail() {
      return this.isValidEmail(this.email)
    },
    validatePhone() {
      return this.isValidPhone(this.phone)
    },
    validateComments() {
      return this.isValidTextField(this.comments, 9)
    },
    submitSendForm() {
        if(!this.validateName()) {
            return
        }
        if(!this.validateEmail()){
            return
        }
        if(!this.validatePhone()){
            return
        }
        if(!this.validateComments()){
            return
        }

        this.sendContactForm()
    },
    showThanksModal(){
        this.$refs.thanksRef.showModal()
    },

    showErrorModal(errorMessage) {
      this.$refs.errorModalRef.showModal(errorMessage)
    },

    resetForm() {
        this.name = ""
        this.email = ""
        this.phone = ""
        this.comments = ""
        this.selectedOption = ""
    },

    async sendContactForm() {
        var contactData = {
            'name': this.name,
            'email': this.email,
            'nif': '',
            'phoneNumber': this.phone,
            'comments': this.comments,
            'subject': this.selectedOption
        }

        var params = getContactmParams(contactData)
        const config = {};

        var response = await submitContact(params, config)
        if (response.isValidContact) {
            this.showThanksModal();
            this.resetForm();
        } else {
            this.showErrorModal(this.$t('validation.error_ocurred'));
            
        }
    }
  }
}

</script>

<style lang="scss" scoped>

.orangeBackground {
    position: absolute;
    z-index: -100;
    width: 100%;
    margin-top: -125px;
    min-height: 900px;
}

.white-box {
    background: #ffffff;
    padding: 40px;
    border-radius: 10px;
    -webkit-box-shadow: 6px 9px 24px -8px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 6px 9px 24px -8px rgba(0, 0, 0, 0.46);
    box-shadow: 6px 9px 24px -8px rgba(0, 0, 0, 0.46);
    margin-top: 50px;
}

.white-box .description {
    font-size: 22px;
}

#claim-general {
    position: relative;
    z-index: 999;
    margin-bottom: 60px;
}
</style>
