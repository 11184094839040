<template>
    <div id="container-order">
        <section>
            <HeaderOrderComponent
            :currentLanding= this.currentLanding />
             <div class="container main-box">
                <div class="col-12">
                    <div class="row section-content">
                        <h2 class="section-title pageTitle">{{ $t('order.error.title') }}</h2>
                    </div>
                </div>
            </div>
            <div class="container">
                <p class="error-text">{{ $t('order.error.check_data') }}</p>
                <p class="error-text">{{ $t('order.error.contact_with_us') }}</p>
                <hr>
                <p class="error-text error-text--orange">{{ $t('order.error.payment_rejected') }}</p>
            </div>
            <div class="container">
                <router-link :to="url" class="btn-orange pulse-effect">{{ $t('order.error.return') }}</router-link>
            </div>
        </section>
    </div>
</template>
<script>
import HeaderOrderComponent from "@/components/order/common-components/HeaderOrderComponent.vue";
import { getters } from '@/state/store.js'

/* API-REST */
import { getGenericItemParams } from '@/api/ApiClientParamsSetter.js'
import { createOrUpdateGenericItem } from '@/api/ApiClient.js'

export default {
    components: {
        HeaderOrderComponent
    },
    computed: {
        currentLanding: getters.currentLanding
    },
    data(){
        return {
            isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
        }
    },
    methods: {
        async sendOrderErrorAnalytic() {
            console.log(this.$route.query.phone);
            const id = document.cookie.match('(^|;)\\s*_ga\\s*=\\s*([^;]+)')?.pop() || 'GA';
            var params = getGenericItemParams(this.currentLanding, id, {"phone": '+' + this.$route.query.phone.trim(), "name": this.$route.query.name}, "payment_denied");
            var result = await createOrUpdateGenericItem(params);
            console.log(result);
        }
    },
    created() {
        if(!this.isEnableOrderProcess) {
            this.$router.push({ name: 'home' })
        }
        this.url = '/order'+window.location.search;
    },
    mounted() {
        if (typeof this.$route.query.policy_id == 'undefined') {
            this.$router.push('/');
        }

        this.sendOrderErrorAnalytic();
    }
}
</script>

<style lang="scss" scoped>

.container {
    max-width: 600px;

    .error-text {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: $blue-color-light;
        padding-right: 25px;

        &--orange {
            color: $orange-color;
            margin-bottom: 30px;
        }
    }

    .section-title {
        font-weight: 600;
        margin-top: 7px;
        font-size: 18px;
        line-height: 20px;
        color: $red-color;
        padding: 15px 0;
    }

    a.btn-orange {
        -webkit-appearance: button;
        -moz-appearance: button;
        appearance: button;
    }
}

.pulse-effect {
    width: 100%;
    transition: all 0.300s;
    animation: pulse 0.6s infinite linear alternate;
    margin-bottom: 40px;
}

.pulse-effect:hover {
   background: $red-hover;
}

@keyframes pulse{
    0%{
        transform: scale(1);
    }100%{
        transform: scale(1.05);
    }
}

</style>
