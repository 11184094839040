<template>
    <div>
        <section class="distribuidor contratacion">
            <div class="fondoNaranja mt-0">
                <img src="@/assets/bgNaranja.svg" class="img-fluid"/>
            </div>
            <div v-if="isValidLanding" class="container">
                <div class="row">
                    <div class="col-6 offset-3">
                        <h1 class="titulo mt-5 white text-center">
                            {{ this.$t('discount.title', { distributor: distributorName}) }}
                        </h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-10 offset-1 col-md-6 offset-md-3 cajaPasos mt-3 sr-delay-8">
                        <div class="text-center mt-4 mb-4">
                            <div class="row align-items-center logos-container">
                                <div class="col-md-5 offset-md-1 col-12 text-center container-logo-insurama" :class="{ 'offset-3': !distributorLogo}">
                                    <img alt="Logo" src="@/assets/logo/mx_insurama.svg" class="logo-insurama">
                                </div>
                                <div v-if="distributorLogo" class="col-md-6 col-12 text-center">
                                    <img alt="Logo distribuidor" :src="distributorLogo" class="logo-distribuidor">
                                </div>
                            </div>
                        </div>
                        <form @submit.prevent.stop="verifyDiscountCode">
                            <div class="form-group">
                                <label for="name">{{ this.$t('discount.label_discount_input') }}</label>
                                <div class="input-group">
                                    <input type="text" v-model="code" class="form-control" required
                                           :placeholder="this.$t('discount.placeholder_discount_input') " name="codigo" id="codigo">
                                    <input type="hidden" :value="distributorId" id="distributorId">
                                    <div class="input-group-append">
                                        <button id="boton-distribuidor"
                                                class="btn btn-linear center-block btn-siguiente mb-4"
                                                :disabled="isSending">
                                            <span v-if="isSending">
                                                <mdi-icon icon="mdiLoading" spin class="mr-3" />
                                              <span>{{ this.$t('discount.button_loading') }}</span>
                                            </span>
                                            <span v-else>{{ this.$t('discount.button_activate_discount') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="form-group text-center">
                            <div class="orangeRed" id="texto-explicacion">
                                {{ errorMsg }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="container">
                <div class="row">
                    <div class="col-6 offset-3">
                        <h1 class="titulo mt-5 white text-center text-white"> {{ this.$t('discount.distributor_error') }} </h1>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Vue from "vue";
import { getDiscountsInABoxLandingData, validateDiscountsInABoxCode } from "@/api/ApiClient";

export default {
    name: 'DiscountsBoxComponent',
    props: {
        landing: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            code: '',
            errorMsg: '',
            landingData: {},
            distributorData: {},
            isSending: false,
            isValidLanding: true
        }
    },
    computed: {
        distributorId() {
            return this.distributorData.id
        },
        distributorName() {
            return this.distributorData.name
        },
        distributorLogo() {
            return this.landingData.logo_path
        }
    },
    mounted() {
        this.getLandingData();
    },
    methods: {
        async verifyDiscountCode() {
            this.isSending = true;

            try {
                const codeData = await validateDiscountsInABoxCode(this.code, this.distributorId)
                const codeIsInvalid = codeData.discount_validation !== undefined && codeData.discount_validation !== 1

                if (codeIsInvalid) {
                    if (codeData.discount_validation === -1) {
                        this.errorMsg = 'El código introducido ya ha sido utilizado, para continuar introduzca otro código'
                    } else {
                        this.errorMsg = 'El código introducido no es válido, para continuar introduzca un código válido'
                    }
                    throw new Error("Invalid code")
                }

                this.errorMsg = ""
                const orderParams = {
                    u: codeData.landing,
                    id: codeData.product_id,
                    id_category: codeData.product_category_id,
                    price: codeData.min_value,
                    landing_param: this.landing,
                    code: this.code
                }
                await this.$router.push({name: 'orderdiscounts', query: orderParams});
            } catch {
                // Nothing to do
            } finally {
                this.isSending = false
            }
        },
        async getLandingData() {
            try {
                const landing = await getDiscountsInABoxLandingData(this.landing)

                Vue.set(this.$data, 'landingData', landing)
                Vue.set(this.$data, 'distributorData', landing.distributor)
            } catch {
                this.isValidLanding = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

.fondoNaranja {
    position: absolute;
    z-index: -100;
    width: 100%;
    margin-top: -125px;
}

h1 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2;
    color: white;
}

section {
    min-height: 80vh;
}

.contratacion {
    font-family: Source Sans Pro;
    color: #495371;

    .form-group {
        margin-bottom: 0.5rem;

        label {
            margin-bottom: 2px;
            font-size: 0.8rem;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}


element.style {
    height: auto;
    visibility: visible;
}

.cajaPasos {
    background: #FFFFFF;
    box-shadow: 0px 6px 7px rgba(73, 83, 113, 0.08);
    border-radius: 4px;
    width: 100%;
    margin-bottom: 2px;
    border: 1px solid #f4f4f4;
    height: auto;
    visibility: visible;
}

.input-group > .form-control {
    position: relative;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    width: 100%;
    min-width: 0;
    margin-bottom: 0;
}

.input-group-append {
    margin-left
    .btn, .input-group-prepend .btn {
        border: 1px solid #CED4DA;
    }
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.orangeRed {
    color: #F82733;
}

.logo-insurama {
    height: 72px;
    max-width: 100%;
}

.logo-distribuidor {
    max-width: 128px;
    max-height: 90px;
    height: auto;
    width: 100%;
    object-fit: contain;
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 1.5rem;
    }
}
@media only screen and (min-width: 766px) and (max-width: 992px) {
    .logos-container{
        flex-direction: column;
    }
    .container-logo-insurama{
        max-width: 100%;
        margin: 0 auto;
    }
}
</style>