const sliderParams = {}

if (process.env.VUE_APP_COUNTRY_CODE == 'it' || process.env.VUE_APP_COUNTRY_CODE == 'pt') {
    sliderParams['seguromovil'] = {
        "type": "standard",
        "minValue": "150",
        "maxValue": "925",
        "stepValue": "25",
        "moreAboveText": true,
        "lessBelowText": false
    }
    
    sliderParams['seguropatinete'] = {
        "type": "standard",
        "minValue": "150",
        "maxValue": "925",
        "stepValue": "25",
        "initialValue": "150",
        "moreAboveText": true,
        "lessBelowText": false
    }

    sliderParams['seguromultidispositivo'] = {
        "type": "staggered",
        "arrayValues": ["7000", "17000", "24000", "40000"]
    }

    sliderParams['seguroportatil'] = {
        "type": "standard",
        "minValue": "275",
        "maxValue": "2525",
        "stepValue": "25",
        "moreAboveText": true,
        "lessBelowText": true
    }

    sliderParams['extensiondegarantia'] = {
        "type": "standard",
        "minValue": "5",
        "maxValue": "5000",
        "stepValue": "5",
        "moreAboveText": false,
        "lessBelowText": true
    }
}

if (process.env.VUE_APP_COUNTRY_CODE == 'mx') {
    sliderParams['seguromovil'] = {
        "type": "standard",
        "minValue": "1000",
        "maxValue": "16000",
        "stepValue": "1000",
        "moreAboveText": true,
        "lessBelowText": false
    }
    
    sliderParams['seguropatinete'] = {
        "type": "standard",
        "minValue": "150",
        "maxValue": "125000",
        "stepValue": "1000",
        "initialValue": "150",
        "moreAboveText": true,
        "lessBelowText": false
    }

    sliderParams['seguromultidispositivo'] = {
        "type": "staggered",
        "arrayValues": ["7000", "17000", "24000", "40000"]
    }

    sliderParams['seguroportatil'] = {
        "type": "standard",
        "minValue": "6000",
        "maxValue": "125000",
        "stepValue": "1000",
        "moreAboveText": false,
        "lessBelowText": true
    }

    sliderParams['extensiondegarantia'] = {
        "type": "standard",
        "minValue": "5",
        "maxValue": "125000",
        "stepValue": "5",
        "moreAboveText": false,
        "lessBelowText": true
    }

}

module.exports = sliderParams