 <template>
    <section class="map">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center mb-4">
                    <h1 class="section-title" align="center">{{ $t('storesComponent.title') }}</h1>
                      {{ $t('storesComponent.subtitle') }}
                </div>
                <div id="map" style="width:100%;min-height:55vh" class="text-center align-middle" ><img style="margin-top:30px;" src='@/assets/loader.gif'></div>
                <div style="box-shadow: 0px 6px 7px rgba(73, 83, 113, 0.46);background-color:#ff4C00;width:100%;max-height:70px;">
                    <div class="mt-3 mb-3 col-12 col-md-6 offset-md-3">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" @keyup.enter="getStores" :placeholder="$t('storesComponent.input_placeholder')" v-model="searchInput">
                            <div class="input-group-append">
                                <button class="btn btn-blanco" @click="getStores"><img height=20 src='@/assets/stores/search.png'></button>  
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showStoreList && stores.length>0" class="mt-5 storeRow">
                    <div class=" storeRow-title col-md-12 mb-4 text-left">{{ $tc("storesComponent.stores_found", stores.length ) }} </div>
                    <div class="storeRow-description"> 
                        <div v-for="store in stores" v-bind:key="store.id" class="col-12 mt-3 col-md-4 text-left">
                            <template v-if="store.data">
                                <h5 class=red>{{store.data.name}}</h5>
                                <p>{{store.data.address}}<br>
                                <a :href="`tel: `+ store.phone_number">{{store.data.phone_number}}</a>
                                <br>{{store.data.city}} ({{store.data.zip}})
                                <br>{{store.data.province}}</p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>

<script>

import { getLocation, getZipByLatLng, getStoresLocations, addMarkers } from "./js/mapUtils.js"

import mapCoordinates from '@/config/constants/mapCoordinates.js'

export default {
    name: "StoresComponent",
    props: ['showStoreList'],
    data: function () {
        return {
            map: null,
            searchInput: "",
            gettingLocation: false,
            geo : { lat: 0, lng: 0 },
            stores: [],
            mapCoordinates
        }
    },
    methods: {
        async locateMe() {
            this.gettingLocation = true;
            try {
                var position = await getLocation();
                this.gettingLocation = false;
                this.showPosition(position.coords)
            } catch(e) {
                position = { latitude: mapCoordinates.latitude, longitude: mapCoordinates.longitude };
                this.gettingLocation = false;
                this.errorStr = e.message;
                this.getStores()
            }
        },
        async showPosition (position) {
            this.geo['lat'] = position.latitude;
            this.geo['lng'] = position.longitude;
            var zip = await getZipByLatLng(position.latitude, position.longitude)
            this.searchInput = zip
            this.getStores(position)
        },
        async getStores(position){
            this.stores = await getStoresLocations(this.searchInput)
            this.createMap(this.stores, position)
        },
        createMap(storeLocations){
            var mapOptions = {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                zoom: mapCoordinates.zoom,
                center: new window.google.maps.LatLng(mapCoordinates.latitude, mapCoordinates.longitude),
                styles: [{ elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] }, { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] }, { elementType: 'labels.text.fill', stylers: [{ color: '#616161' }] }, { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f5f5' }] }, { featureType: 'administrative.land_parcel', elementType: 'labels.text.fill', stylers: [{ color: '#bdbdbd' }] }, { featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#eeeeee' }] }, { featureType: 'poi', elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] }, { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#e5e5e5' }] }, { featureType: 'poi.park', elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] }, { featureType: 'road', elementType: 'geometry', stylers: [{ color: '#ffffff' }] }, { featureType: 'road.arterial', elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] }, { featureType: 'road.highway', elementType: 'geometry', stylers: [{ color: '#dadada' }] }, { featureType: 'road.highway', elementType: 'labels.text.fill', stylers: [{ color: '#616161' }] }, { featureType: 'road.local', elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] }, { featureType: 'transit.line', elementType: 'geometry', stylers: [{ color: '#e5e5e5' }] }, { featureType: 'transit.station', elementType: 'geometry', stylers: [{ color: '#eeeeee' }] }, { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#c9c9c9' }] }, { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] }]
            }
            
            if(storeLocations.length == 0){
                new window.google.maps.Map(document.getElementById('map'), mapOptions);
            }else{
                var googleMap = new window.google.maps.Map(document.getElementById('map'), mapOptions);
                addMarkers(storeLocations, googleMap)
            }
        }
    },
    mounted(){
        this.locateMe()
    }
}
</script>

<style lang="scss" scoped>

section.map {
    margin-top: 60px;
}


.btn-blanco {
    background: $white-color;
    color: $orange-color;
    text-align: center;
    border-radius: 5px;
}

.storeRow {
    width: 100%;
    background: $gray-color-light;
    box-shadow: 0px 6px 7px rgba(73, 83, 113, 0.46);
    position: relative;
    padding: 20px;
}
.storeRow .storeRow-description{
    display: flex;
    flex-wrap: wrap;
}


</style>  


 